import React, { useEffect, useRef, useState } from "react";
import param from "../param.json";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Autocomplete,
  IconButton,
  TextField,
  DialogActions,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { mdiClose } from "@mdi/js";

import Icon from "@mdi/react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ReactComponent as ApiSvg } from "../img/logos/Api.svg";

var Api = "_api.php";
var srv = "_srv.php";
var typ = "_params.php";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddAllDialog({ apps, GetData }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [dataChanged, setDataChanged] = useState(false); // eslint-disable-line no-unused-vars

  const [tabs] = useState([
    { id: 1, name: "Api" },
    { id: 2, name: "Services" },
    { id: 3, name: "Parametres" },
  ]);
  const nameInputRef = useRef(null);
  const noteInputRef = useRef(null);

  const [app, setApp] = useState([]);
  const [api, setApi] = useState({});
  const [apis, setApis] = useState(null);
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});
  const [parametre, setParametre] = useState({});
  const [parametres, setParametres] = useState([]);
  const [value, setValue] = useState(1); // changer la valeur de lautofocus
  const [requis, setRequis] = useState(true);
  const [direction, setDirection] = useState("1");
  const [note, setNote] = useState("");
  const [checked, setChecked] = useState(true); // eslint-disable-line no-unused-vars
  const [name, setName] = useState("");
  const [data, setData] = useState(); // eslint-disable-line no-unused-vars
  const selectedApp = apps[0];
  const [selectedDirection, setSelectedDirection] = useState(1); // Par défaut, IN est sélectionné

  const getApis = async (id) => {
    setLoading(true);
    await axios
      .get(`${param.urlService}${Api}?do=gets&app=${id}`)
      .then((response) => {
        if (!response.data.err) {
          setApis(response.data.jData);

          if (response.data.jData.length > 0) {
            setApi(response.data.jData[0]);
          } else {
            setApi({});
          }
        } else {
          alert(response.data.err);
        }
      })
      .catch((message) => {
        alert(message);
      });
    setLoading(false);
  };

  const Addapi = async () => {
    var data = { app: app.id, name: name };
    try {
      const res = await axios.post(`${param.urlService}${Api}?do=add`, data);
      if (!res.data.err) {
        alert("API ajoutée avec succès!");
        setName("");
        // Mettre à jour la liste des APIs après l'ajout
        getApis(app.id);
        // Mettre à jour les services associés à l'API sélectionnée (si nécessaire)
        if (api.id) {
          getServices(api.id);
        }
        updateData(res.data.jData);
        GetData();
      } else {
        alert(res.data.err);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'API:", error);
      alert(`Erreur lors de l'ajout de l'API. Veuillez réessayer.`);
    } finally {
      setLoading(false);
    }
  };

  const getServices = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${param.urlService}${srv}?do=gets&api=${id}`
      );
      if (!response.data.err) {
        setServices(response.data.jData);
        if (response.data.jData.length > 0) setService(response.data.jData[0]);
        else setService({});
        setDataChanged(true);
      } else {
        alert(response.data.err);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const AddService = async () => {
    setLoading(true);
    var data = { api: api.id, name: name };

    try {
      const response = await axios.post(
        `${param.urlService}${srv}?do=add`,
        data
      );

      if (!response.data.err) {
        const newService = response.data.jData;
        setServices((prevServices) => [...prevServices, newService]);
        setService(newService);
        alert("Service ajouté avec succès!");
        setName("");
        setValue(2);

        getServices(api.id);
        setApi(api);
        updateData(response.data.err);
        GetData();
      } else {
        alert(response.data.err);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du service:", error);
      alert(`Erreur lors de l'ajout du service. Veuillez réessayer.`);
    } finally {
      setLoading(false);
    }
  };

  const getParamTypes = async (id) => {
    setLoading(true);
    await axios
      .get(`${param.urlService}${typ}?do=gettyps`)
      .then((res) => {
        if (!res.data.err) {
          setParametres(res.data.jData);
          if (res.data.jData.length > 0) setParametre(res.data.jData[0]);
          else setParametre({});
        } else {
          alert(res.data.err);
        }
      })
      .catch((message) => {
        alert(message);
      });
    setLoading(false);
  };

  const AddParametres = async () => {
    if (service && service.id && parametre && parametre.id) {
      setLoading(true);

      var data = {
        srv: service.id,
        name: name,
        typ: parametre.id,
        req: requis,
        drct: direction,
        note: note,
      };

      try {
        const response = await axios.post(
          `${param.urlService}${typ}?do=add`,
          data
        );

        if (!response.data.err) {
          alert("Paramètre ajouté avec succès!");
          setValue(3);
          updateData(response.data.jData);
          setName('')
         noteInputRef.current.value = "";
          GetData();
          // setOpen(false);
        } else {
          alert(response.data.err);
        }
      } catch (error) {
        console.error("Error Adding Parameter:", error);
        alert(`Erreur lors de l'ajout du paramètre. Veuillez réessayer.`);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Veuillez sélectionner un service et un type.");
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleRadioChange = (event) => {
    const directionValue = event.target.value;
    setDirection(directionValue);
    setSelectedDirection(directionValue);
  };

  const handleAppChange = (value) => {
    setApis([]);
    setApp(value);
    getApis(value.id);
    if (apis.length > 0) {
      const newApi = apis.find((api) => api.app === value.id);
      if (newApi) {
        setApi(newApi);
        if (newApi.id) {
          getServices(newApi.id);
        }
      } else {
        setApi({});
        setServices([]);
        setService({}); // Réinitialise la valeur du service à une valeur vide
      }
    }
  };

  const handleApiChange = (event, value) => {
    event.preventDefault();
    setApi(value);
    if (value && value.id) {
      getServices(value.id);
    }
    if (services.length > 0) {
      const newSrv = services.find((service) => service.api === value.id);
      if (newSrv) {
        setService(newSrv);
      } else {
        setService({});
      }
    } else {
      setService({});
    }
  };

  const updateData = (newData) => {
    setData(newData);
  };

  useEffect(() => {
    if (api && api.id) {
      getServices(api.id);
      setService({});
    }
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }  
  }, [api]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === 1) {
      Addapi();
    } else {
      if (value === 2) {
        AddService();
      }
    }
    if (value === 3) {
      AddParametres();
    }
         if (nameInputRef.current) {
           nameInputRef.current.focus();
         } 
  };

  const handleClickOpen = () => {
    if (apps.length > 0) {
      setApp(selectedApp);
      getApis(selectedApp.id);
    }
    setOpen(true);
    setApi({});
    setService({});
    setParametre({});
  };

  useEffect(() => {
    getParamTypes();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setParametre({});
    setRequis(true);
    setDirection("1");
    setNote("");
  };

  const handleClose = () => {
    setOpen(false);
  };
 useEffect(() => {
   setName("");
   if (nameInputRef.current) {
     nameInputRef.current.focus();
   }
 }, [value]);

  return (
    <React.Fragment>
      <Tooltip title=" Ajouter Api, Service,Parametre">
        {" "}
        <Button onClick={handleClickOpen}>
          <ApiSvg />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Veuillez Ajouter
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form id="Dialog" style={{ width: "500px" }} onSubmit={handleSubmit}>
            <Tabs value={value} onChange={handleChange} centered>
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  label={tab.name}
                  value={tab.id}
                  autoFocus={tab.id === value}
                ></Tab>
              ))}
            </Tabs>
            <Autocomplete
              defaultValue={selectedApp}
              style={{ marginTop: "10px", marginBottom: "10px" }}
              options={apps}
              onChange={(event, value) => handleAppChange(value)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Apps" />}
            />

            <div
              className={
                value === 2 ? "gradRow1" : value === 3 ? "gradRow2" : ""
              }
            >
              {value !== 1 && (
                <>
                  <Autocomplete
                    value={api}
                    options={Array.isArray(apis) ? apis : []}
                    onChange={(e, newValue) => handleApiChange(e, newValue)}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Apis" />
                    )}
                  />
                </>
              )}

              {value === 3 && (
                <>
                  <Autocomplete
                    value={service}
                    // defaultValue={services.length > 0 ? services[0] : null}
                    options={Array.isArray(services) ? services : []}
                    onChange={(e, newValue) => setService(newValue)}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Services" />
                    )}
                  />
                </>
              )}
              <TextField
                label="Nom"
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                fullWidth
                required
                value={name}
                autoFocus
                inputRef={nameInputRef}
              />
              {value === 3 && (
                <>
                  <Autocomplete
                    options={parametres}
                    onChange={(e, newValue) => setParametre(newValue)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Types" />
                    )}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={requis} />}
                    onChange={handleCheckboxChange}
                    label="requis"
                    disabled={selectedDirection === "2"}
                  />
                  {/* <Checkbox
                              checked={checked} label="requis" 
                            onChange={handleCheckboxChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                   /> */}

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={direction}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="IN"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Out"
                    />
                  </RadioGroup>
                  <TextField
                    style={{ gridColumn: "span 3" }}
                    id="Note"
                    label="Note"
                    multiline
                    rows={3}
                    fullWidth
                    inputRef={noteInputRef}
                  />
                </>
              )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" form="Dialog" type="submit">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
