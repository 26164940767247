import React, { useState, useCallback } from 'react';
import { Paper, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiClose, mdiTextBoxEdit } from '@mdi/js';
import '../style/Fellaglobal.css';
import EditedText from './FellaEditableText';
import { Checkbox } from '@mui/material';
import { getHeader } from '../Gfunc';
import axios from 'axios';
import param from '../param.json';
import EditableSelect from './EditableSelect';
import EditedCheckbox from './FellaEditabelCheckBox';
import EditedRadioButton from './FellaEditableRadioButton';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function EditedParam({ serviceData, fetchData, selectedApp }) {

    const [open, setOpen] = useState(false);
    const [editingName, setEditingName] = useState({});
    const [editingType, setEditingType] = useState({});
    const [editingDirection, setEditingDirection] = useState({});
    const [editingMust, setEditingMust] = useState({});
    const [editingNote, setEditingNote] = useState({});
    const [types, setTypes] = useState([]);
    const api3 = '_params.php';

    const handleClickOpen = (paramId, columnName) => {
        setOpen(true);
        if (columnName === 'Nom') {
            setEditingName(paramId);
        } else if (columnName === 'Type') {
            setEditingType(paramId);
        } else if (columnName === 'Direction') {
            setEditingDirection(paramId);
        } else if (columnName === 'Must') {
            setEditingMust(paramId);
        } else if (columnName === 'Note') {
            setEditingNote(paramId);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveParam = async (id, value, columnName) => {
        try {
            const response = await axios.post(`${param.urlService}${api3}`, {
                id: id,
                value: value,
                columnName: columnName
            }, { headers: getHeader() });

            if (response.status === 200) {
                fetchData(selectedApp);
                if (columnName === 'Nom') {
                    setEditingName(prevState => ({ ...prevState, [id]: value }));
                } else if (columnName === 'Type') {
                    setEditingType(prevState => ({ ...prevState, [id]: value }));
                } else if (columnName === 'Direction') {
                    setEditingDirection(prevState => ({ ...prevState, [id]: value }));
                } else if (columnName === 'Must') {
                    setEditingMust(prevState => ({ ...prevState, [id]: value }));
                } else if (columnName === 'Note') {
                    setEditingNote(prevState => ({ ...prevState, [id]: value }));
                }
            } else {
                console.error('Erreur lors de la mise à jour du paramètre');
            }
        } catch (error) {
            console.error(`Erreur lors de la mise à jour du paramètre: ${error}`);
        }
    };

    const getTypes = async () => {
        try {
            const response = await axios.get(`${param.urlService}${api3}?do=gettyps`, { headers: getHeader() });
            console.log(response);
            if (!response.data.err) {
                setTypes(response.data.jData);
            } else {
                alert(response.data.err);
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const handleOpenTypes = useCallback(async () => {
        getTypes();
    }, [getTypes]);

    return (
        <>
            <Tooltip title="Editer la liste des paramètres" arrow>
                <IconButton onClick={handleClickOpen} >
                    <Icon path={mdiTextBoxEdit} size={1} style={{ color: '#004579' }} />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{ style: { height: '650px', width: '1000px' } }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Editer la liste des paramètres du service : {serviceData.serviceName}
                    <Tooltip title="Fermer" arrow>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent>
                    <div style={{ paddingTop: '35px', paddingLeft: '8px', paddingRight: '8px' }}>
                        <TableContainer component={Paper} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 230px)', overflowX: 'hidden' }}>
                            <Table aria-label="simple table" className="table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: '#1B7EBE' }}>Nom</TableCell>
                                        <TableCell style={{ backgroundColor: '#1B7EBE' }}>Type</TableCell>
                                        <TableCell style={{ backgroundColor: '#1B7EBE' }}>Direction</TableCell>
                                        <TableCell style={{ backgroundColor: '#1B7EBE' }}>Requis</TableCell>
                                        <TableCell style={{ backgroundColor: '#1B7EBE' }}>Note</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serviceData.params.map(param => (
                                        <TableRow key={param.paramId} >
                                            <TableCell onClick={() => handleClickOpen(param.paramId, 'Nom')} className='smaller-name'>
                                                {editingName === param.paramId ? (
                                                    <EditedText
                                                        att='name'
                                                        id={param.paramId}
                                                        api={"_params.php"}
                                                        aux={`&srv=${serviceData.serviceId}`}
                                                        service={"upd"}
                                                        defaultValue={param.paramName}
                                                        onSave={(value) => handleSaveParam(param.paramId, value, 'Nom')}
                                                        setEditableParam={editingName}



                                                    />
                                                ) : (
                                                    param.paramName
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleClickOpen(param.paramId, 'Type')}>
                                                {editingType === param.paramId ? (
                                                    <EditableSelect
                                                        att='typ'
                                                        val={param.typ[0]}
                                                        id={param.paramId}
                                                        options={types}
                                                        handleOptions={handleOpenTypes}
                                                        api={"_params.php"}
                                                        aux={`&srv=${serviceData.serviceId}`}
                                                        service={"upd"}
                                                        Value={param.typ}
                                                        onSave={(value) => handleSaveParam(param.paramId, value, 'Type')}
                                                        setEditableParam={editingType}
                                                        fetchData={fetchData}
                                                        selectedApp={selectedApp}
                                                    />
                                                ) : (
                                                    param.typ
                                                )}
                                            </TableCell>
                                            <TableCell onClick={() => handleClickOpen(param.paramId, 'Direction')} className='smaller-direction'>
                                                {editingDirection === param.paramId ? (
                                                    <EditedRadioButton
                                                        att='direction'
                                                        id={param.paramId}
                                                        api={"_params.php"}
                                                        aux={`&srv=${serviceData.serviceId}`}
                                                        service={"upd"}
                                                        defaultValue={param.direction === "1" ? "Input" : "Output"}
                                                        onSave={(value) => handleSaveParam(param.paramId, value === "Input" ? "1" : "0", 'Direction')}
                                                        setEditableParam={editingDirection}


                                                    />
                                                ) : (


                                                    <RadioGroup row>
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio />}
                                                            label="In"
                                                            checked={param.direction === "1"}
                                                        />
                                                        <FormControlLabel
                                                            value="0"
                                                            control={<Radio />}
                                                            label="Out"
                                                            checked={param.direction !== "1"}
                                                        />
                                                    </RadioGroup>
                                                )}
                                            </TableCell>



                                            <TableCell onClick={() => handleClickOpen(param.paramId, 'Must')} className='smaller-must'>
                                                {editingMust === param.paramId ? (
                                                    <EditedCheckbox
                                                        att='must'
                                                        id={param.paramId}
                                                        api={"_params.php"}
                                                        aux={`&srv=${serviceData.serviceId}`}
                                                        service={"upd"}
                                                        defaultValue={param.must === "1"}
                                                        onSave={(value) => handleSaveParam(param.paramId, value ? "1" : "0", 'Must')}
                                                        setEditableParam={editingMust}


                                                    />
                                                ) : (
                                                    <div>
                                                        <Checkbox checked={param.must === "1"} />
                                                        <span style={{ marginLeft: 5, color: param.must === "1" ? "black" : "gray" }}>Requis</span>
                                                    </div>


                                                )}
                                            </TableCell>


                                            <TableCell onClick={() => handleClickOpen(param.paramId, 'Note')} className='smaller-note'>
                                                {editingNote === param.paramId ? (
                                                    <EditedText
                                                        att='note'
                                                        id={param.paramId}
                                                        api={"_params.php"}
                                                        aux={`&srv=${serviceData.serviceId}`}
                                                        service={"upd"}
                                                        defaultValue={param.note}
                                                        onSave={(value) => handleSaveParam(param.paramId, value, 'Note')}
                                                        setEditableParam={editingNote}


                                                    />
                                                ) : (
                                                    param.note
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

