import './App.css';
import { theme } from "./style/imene_theme.js"
import { ThemeProvider } from '@mui/material/styles';
import './style/imene_style.css';
import ListApp from './components/imene_listApp.js';
import React, { useEffect, useState } from 'react';
import DataTable from './components/imene_dataTable.js';
import axios from 'axios';
import { getHeader } from './Gfunc.js';
// import { Version } from './components/imene_version.js';
import { Tooltip } from '@mui/material'
import { ReactComponent as Applogo } from './img/logos/logo2.svg';
import param from './param.json';


function MainImene({ setName }) {
  const [appSelected, setselectedApp] = useState(null)
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(true)
  async function getApp() {
    try {
      const response = await axios.get(param.urlService + '_app.php?do=gets', { headers: getHeader() });
      setLoading(false)
      return (response.data.jData);

    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }

  }
  useEffect(() => {
    async function getData() {
      let jdata = await getApp();
      setApplications(jdata);
    }
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlesetselectedApp = (newapp) => {
    setselectedApp(newapp)
  };
  return (
    <ThemeProvider theme={theme}>
      <div className='main-div'>
        <Tooltip title='Quiter'>
          <Applogo onClick={setName} className='logo' />
        </Tooltip>
        <ListApp onselected={handlesetselectedApp} applications={applications} loading={loading} />
      </div>
      <DataTable applications={{ apps: applications, selectedApp: appSelected }} />
      {/* <Version /> */}
    </ThemeProvider>);

}

export default MainImene;
