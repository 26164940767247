import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import MainImene from './imene_main.js';
import ChaimaMain from './ChaimaMain';
import MainFella from './FellaMain';
import MainFella2 from './FellaMain2';
import Footer from './components/FellaFooter.js';

function App() {
  const [name, setSelectedName] = useState('');
  function handleClick(name) {
    setSelectedName(name)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {

      setSelectedName('')
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return <div className={(name === '') ? 'firstPage' : null}>
    {name === '' && <div className='div-firstpage' onClick={() => handleClick('Sarah')}>Sarah</div>}
    {name === '' && <div className='div-firstpage' onClick={() => handleClick('Imene')}>Imene</div>}
    {name === '' && <div className='div-firstpage' onClick={() => handleClick('Fella')}>Fella</div>}
    {name === '' && <div className='div-firstpage' onClick={() => handleClick('Chaima')}>Chaima</div>}
    {(name === 'Imene') && <MainImene setName={() => setSelectedName('')} />}
    {(name === 'Fella') && <MainFella setName={() => { setSelectedName('') }} />}
    {(name === 'Sarah') && <MainFella2 setName={() => { setSelectedName('') }} />}
    {(name === 'Chaima') && <ChaimaMain setName={() => { setSelectedName('') }} />}
    <Footer />
  </div>

}

export default App;