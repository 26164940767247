import React from 'react';
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { getHeader } from "../Gfunc";
import param from '../param.json';


export default function AutoCompleteApp({ appname, application, update }) {
  const [selectedApp, setselectedApp] = useState(null)

  async function getApis(AppId) {
    try {
      const response = await axios.get(param.urlService + `_api.php?do=gets&app=${AppId.id}`, { headers: getHeader() });
      return response.data.jData;
    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }
  }
  useEffect(() => {
    if (appname) {
      setselectedApp(appname)
      async function getData() {
        let appis = await getApis(application.find(a => a.name === appname));
        await update(appis, appname);
        //if(appis.length !==0) setApiValue(appis[0].name)
      }
      getData();
    }
  }, [appname])

  function onChangeApp(event, value) {
    setselectedApp(value)
    async function getData() {
      let appis = await getApis(application.find(a => a.name === value));
      await update(appis, value);
      // setApiValue(appis.map((s)=>{return s.name})[0])
    }
    getData();
  };

  return <><Autocomplete
    disableClearable
    value={selectedApp}
    onChange={onChangeApp}
    loading={(application) && application.legnth === 0}
    loadingText={"pas d'options"}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    options={(application != null) ? application.map((app) => app.name) : []}
    sx={{ width: '100%', mb: 1, position: 'sticky' }}
    renderInput={(params) => <TextField {...params} label='Application' required />}
  />
  </>
}
