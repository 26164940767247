import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Tabs, Tab, Autocomplete, DialogActions, TextField, Tooltip, Typography, Button, Dialog, DialogContent, DialogTitle, Paper, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import CheckboxLabels from './FellaCheckboxLabels';
import { mdiClose, mdiMagnify } from '@mdi/js';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import Direction from './FellaDirection'
import { ReactComponent as SvgIcon } from '../img/FellaApi.svg';
import param from '../param.json'


function PaperComponent(props) {
  const draggableRef = useRef(null);
  return (
    <Draggable nodeRef={draggableRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper ref={draggableRef} {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({ value, apps, setFilteredAPI, setSearchTerm, updateData, fetchData }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [tabValue, setTabValue] = useState(0);
  const [name, setName] = useState('');
  const [noteout, setNoteOut] = useState('');
  const [notein, setNoteIn] = useState('');
  const [note, setNOte] = useState('');
  const [app, setApp] = useState(apps[apps.findIndex(obj => obj.id === value)]);
  const [api, setApi] = useState({});
  const [apis, setApis] = useState([]);
  const [service, setService] = useState({});
  const [type, setType] = useState({});
  const [services, setServices] = useState([]);
  const [types, setTypes] = useState([]);
  const [requis, setRequis] = useState(true);
  const [direction, setDirection] = useState("1");// eslint-disable-next-line 
  const [loading, setloading] = useState(false);// eslint-disable-next-line 
  const nameInputRef = useRef(null);
  const noteInputRef = useRef(null);
  const noteInInputRef = useRef(null);
  const noteOutInputRef = useRef(null);






  var Api = '_api.php'
  var api1 = '_srv.php'
  var api3 = '_params.php'



  const getApis = async (id) => {
    setloading(true)
    await axios.get(`${param.urlService}${Api}?do=gets&app=${id}`, { headers: getHeader() })
      .then((response) => {
        if (!response.data.err) {
          setApis(response.data.jData)
          if (response.data.jData.length > 0)
            setApi(response.data.jData[0])
          else setApi({})
        } else {
          alert(response.data.err)
        }
      })
      .catch((message) => {
        alert(message)
      })
    setloading(false)

  }
  const getServices = async (id) => {
    setloading(true)
    await axios.get(`${param.urlService}${api1}?do=gets&api=${id}`, { headers: getHeader() })
      .then((response) => {
        if (!response.data.err) {
          setServices(response.data.jData)
          if (response.data.jData.length > 0)
            setService(response.data.jData[0])
          else setService({})
        } else {
          alert(response.data.err)
        }
      })
      .catch((message) => {
        alert(message)
      })
    setloading(false)

  }
  const getTypes = async () => {
    setloading(true)
    await axios.get(`${param.urlService}${api3}?do=gettyps`, { headers: getHeader() })
      .then((response) => {
        console.log(response)
        if (!response.data.err) {
          setTypes(response.data.jData)
        } else {
          alert(response.data.err)
        }
      })
      .catch((message) => {
        alert(message)
      })
    setloading(false)


  }
  const Addapi = async () => {
    var data = { app: app.id, name: name }
    await axios.post(`${param.urlService}${Api}?do=add`, data, { headers: getHeader() })
      .then((response) => {
        if (!response.data.err) {

          setName('');
          getApis(app.id);
          updateData(response.data.jData);
          fetchData();

        } else (alert(response.data.err))
      })
      .catch((message) => {
        alert(message)
      })
  }


  const Addsrv = async () => {
    var data = { api: api.id, name: name, notein: notein, noteout: noteout }
    await axios.post(`${param.urlService}${api1}?do=add`, data, { headers: getHeader() })
      .then((response) => {
        if (!response.data.jData.err) {
          setName('');
          setNoteIn('');
          setNoteOut('');
          getServices(api.id);
          setApi(api);
          updateData(response.data.jData);
          fetchData();
          // noteInputRef.current.value = '';
          // noteOutInputRef.current.value = '';


        } else (alert(response.data.jData.err))
      })
      .catch((message) => {
        alert(message)
      })
  }


  const Addparams = async () => {
    var data = { srv: service.id, name: name, typ: type.id, req: requis, drct: direction, note: note };
    if (service && service.id && type && type.id) {
      await axios.post(`${param.urlService}${api3}?do=add`, data, { headers: getHeader() })
        .then((response) => {
          if (!response.data.err) {
            setName('');
            setNOte('');
            updateData(response.data.jData);
            fetchData();
            noteInputRef.current.value = '';


          } else {
            alert(response.data.err);

          }
        })
        .catch((message) => {
          alert(message);
        });
    } else {
      alert('Veuillez sélectionner un service et un type.');
    }
  };



  const handleTabChange = (event, newValue) => {

    setTabValue(newValue);

  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (tabValue === 0) {
      Addapi()
    } else {
      if (tabValue === 1) {
        Addsrv()
      } else {
        Addparams();
      }
    }
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }

  };




  const handleAppChange = (event, value) => {
    setApis([]);
    event.preventDefault();
    if (value && value.id) {
      setApp(value);
      getApis(value.id);
    } else {
      console.error("Invalid value or value.id is null");
    }
  };


  const handleApiChange = (event, value) => {
    event.preventDefault();
    setApi(value);

  };

  const handleFilterChange = (event, value) => {
    event.preventDefault();


    if (value && value.name === "Aucun filtre") {
      if (setFilteredAPI) {
        setFilteredAPI(null);
      }
      if (setApi) {
        setApi(value);
      }
      //setSelectedData({ api: data.api || {} });
    } else {
      const selectedApi = value ? { [value.id]: value } : {};
      if (setFilteredAPI) {
        setFilteredAPI(value ? value.id : null);
      }
      if (setApi) {
        setApi(value);
      }
      // setSelectedData({ apis: selectedApi });
    }
  };

  let handleSearchTermChange = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchTerm(lowerCase);

  };

  const openApp = () => {

    const index = apps.findIndex(obj => obj.id === value)
    const app = {}
    app['id'] = value;
    app['name'] = apps[index].name
    setApp(app)

  }
  const handleClickOpen = () => {
    console.log(apps)
    console.log(apps[apps.findIndex(obj => obj.id === value)])
    setApp(apps[apps.findIndex(obj => obj.id === value)])
    setOpen(true);
    setApi({});
    setService({});
    setType({});
    openApp();
    getApis(value);
  };


  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'l') {
      window.document.getElementById("addAll").click();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    if (api && api.id) {
      getServices(api.id);
      setService({})
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    getTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setName('');
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }

  }, [tabValue]);







  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);



  useEffect(() => {
    const fetchApis = async () => {
      try {
        setloading(true);
        const response = await axios.get(`${param.urlService}${Api}?do=gets&app=${value}`, { headers: getHeader() });
        if (!response.data.err) {
          setApis(response.data.jData);
          if (response.data.jData.length > 0) {
            setApi(response.data.jData[0]);
          } else {
            setApi({});
          }
        } else {
          alert(response.data.err);
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setloading(false);
      }
    };

    fetchApis();
  }, [value, Api]);



  return (
    <>

      <div className="flex-wrapper1">

        <div className='search'>
          <Icon path={mdiMagnify} size={0.9} style={{ color: '#1B7EBE', marginTop: '21px' }} />
          <TextField
            label="Rechercher"
            variant="standard"
            onChange={handleSearchTermChange}
            fullWidth
            required
            sx={{ width: 250 }}
          />
        </div>

        <div className="apiField">

          <Autocomplete
            sx={{ width: 250 }}
            onChange={(e, newValue) => handleFilterChange(e, newValue, setFilteredAPI)}
            options={[{ name: "Aucun filtre" }, ...apis]}
            getOptionLabel={(option) => option.name ? option.name : ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtrer"
                variant='standard'
              /*InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiFilter} size={0.9} style={{ color: '#1B7EBE' ,marginLeft:'3px' }} />
                  </InputAdornment>
                ),
              }}*/
              />
            )}
          />
        </div>
        <div className="addButton" style={{ lineheight: 'normal' }}>
          <Tooltip title="Ajouter : Api, Service, Paramètre : Ctrl+l" arrow>
            <Button id="addAll" onClick={handleClickOpen} >
              <SvgIcon />
            </Button>
          </Tooltip>
        </div>

      </div>



      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={fullScreen}
        PaperProps={{ style: { height: '450px', width: '800px' } }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography variant="body3"> Ajouter un :</Typography>
          <Tooltip title="Fermer" arrow>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <Icon path={mdiClose} size={1} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent style={{ display: 'block' }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label={<Typography variant="body3"> API </Typography>} />
            <Tab label={<Typography variant="body3"> SERVICE </Typography>} />
            <Tab label={<Typography variant="body3"> Paramètre </Typography>} />
          </Tabs>
          <form id='adds' style={{ width: '100%', display: 'grid', rowGap: '10px', marginTop: '13px' }} onSubmit={handleSubmit}>
            <Autocomplete
              style={{ width: '100%', display: 'grid', rowGap: '10px', marginTop: '40px', position: 'relative' }}
              value={app}
              onChange={(e, newValue) => handleAppChange(e, newValue)}
              options={apps}
              getOptionLabel={(option) => option.name ? option.name : ""}
              renderInput={(params) => <TextField {...params} label="Applications" />}
            />

            <div className={tabValue === 1 ? 'row-1' : tabValue === 2 ? 'row-2' : ''}>
              {tabValue !== 0 && (
                <div>
                  <Autocomplete

                    value={api}
                    onChange={(e, newValue) => handleApiChange(e, newValue, setApi)}
                    options={apis}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    renderInput={(params) => <TextField {...params} label="Api" />}
                  />

                </div>
              )}

              {tabValue === 2 ?
                <div>
                  <Autocomplete

                    value={service}
                    onChange={(e, newValue) => setService(newValue)}
                    options={services}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => <TextField {...params} label="Service" />}

                  />

                </div>
                : null}

              <TextField
                id="name-input" label="Nom" variant="outlined" onChange={(e) => { setName(e.target.value); }} autoFocus fullWidth required={(tabValue !== 2) || (tabValue === 2 && direction !== "2")}
                value={name} inputRef={nameInputRef}
              />

              {tabValue === 1 && (
                <>
                  <TextField
                    // style={{ gridColumn: 'span 2 !important', }}
                    label="Note IN"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={notein}
                    onChange={e => { setNoteIn(e.target.value) }}
                    inputRef={noteInInputRef}
                  />
                  <TextField
                    // style={{ gridColumn: 'span 3', }}
                    label="Note OUT"
                    variant="outlined"
                    multiline
                    value={noteout}
                    rows={3}
                    onChange={e => { setNoteOut(e.target.value) }}
                    inputRef={noteOutInputRef}
                  />
                </>
              )}


              {tabValue === 2 ?
                <>
                  <Autocomplete

                    value={type}
                    onChange={(e, newValue) => setType(newValue)}
                    options={types}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => <TextField {...params} label="Type" fullWidth required />}
                  />
                  <CheckboxLabels requis={requis} setRequis={setRequis} disabled={direction === "2"} />
                  <Direction direction={direction} setDirection={setDirection} />
                  <TextField
                    style={{ gridColumn: 'span 3' }}
                    label="Note"
                    variant="outlined"
                    multiline
                    rows={3}
                    onChange={e => { setNOte(e.target.value) }}
                    inputRef={noteInputRef}
                  />
                </> : null}
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Button form='adds' variant="contained" color="primary" type="submit">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}