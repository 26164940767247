import React from 'react';
import { ClickAwayListener, TextField, styled } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { getHeader } from "../Gfunc";
import param from '../param.json';


const StyledTextField = styled(TextField)(() => ({
  alignSelf: 'center',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'lightblue !important',

    '& fieldset': {
      border: 'lightblue !important',

    },
    '& input': {
      width: 'auto',
      fontSize: '14px !important',
      border: 'none !important',
      padding: ' 3px 0 !important',
      paddingLeft: '1ch !important',
    },

  },
}));
export default function EditableComponent({ name, id, setUpdateName, setIsedit, api, service, aux, sub }) {


  const inputRef = useRef(null);
  const [value, setValue] = useState(name)
  const initialWidth = `${name.length + 0.5}ch`;
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${value.length + 0.5}ch`;
    }
  }, [value]);



  async function upd() {
    let data;
    let serv;
    if (api === '_srv.php') {
      let service = await id(sub.idApi.id);
      serv = service.find(s => s.name === name)
      data = { att: 'name', id: (serv !== null) ? serv.id : null, val: value };

    }
    else {
      data = { att: 'name', id: id, val: value };
    }

    try {
      const response = await axios.post(param.urlService + `${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
      console.log('Post response:', response.data);
      if (response.data.jData) {
        if (api === '_srv.php') setUpdateName(sub.idApi.name, serv.name, value)
        else setUpdateName(name, id, value)
      }
    } catch (error) {
      console.error('Error posting data:', error);
      alert(error)
    }
  }

  function clickaway() {
    setIsedit()
    upd();


  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsedit();
      if (value !== name) {
        upd();
      }
    }
  };
  function handleOnBlur(event) {
    if (name !== event.target.value) {
      setIsedit();
      upd();

    }
  }
  return <ClickAwayListener onClickAway={clickaway}>
    <StyledTextField
      //defaultValue={name}
      inputRef={inputRef}
      autoFocus
      onBlur={handleOnBlur}
      onKeyDown={handleKeyDown}
      type="text"
      onChange={(e) => setValue(e.target.value)}
      value={value}
      inputProps={{ disableunderline: 'true', style: { width: initialWidth, } }}
    />
  </ClickAwayListener>
    ;

}