import React, { useEffect, useState } from 'react'
import './App.css';
import './style/Chaimaglobal.css';
import CenteredTabs from './components/ChaimaCenteredTabs.js';
import AddAppDialog from './components/ChaimaAddAppDialog.js';
import EditAppDialog from './components/ChaimaEditAppDialog.js';
import DataTable from './components/ChaimaDataTable.js';
import {theme} from "./style/Chaimatheme.js"
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Box, LinearProgress, Button,Tooltip } from '@mui/material';
import { ReactComponent as Logo2 } from './img/logos/logo2.svg';
import param from "./param.json";

function ChaimaMain({setName}) {
  const api="_app.php"
  const [apps,setApps]= useState([])
  const [loading,setLoading]= useState(false)
  const [data, setData] = useState({});
  const [selectedApp, setSelectedApp] = useState(null); 
  const [selectedData,setSelectedData] = useState({});// eslint-disable-line no-unused-vars
  const [value, setValue] = useState(selectedApp);// eslint-disable-line no-unused-vars


const  fetchApps= async()=>{
    setLoading(true)
    await axios.get(`${param.urlService}${api}?do=gets`)
    .then(res => {
      setApps(res.data.jData)
      if(res.data.jData.length>0)
      setSelectedApp(res.data.jData[0].id)
    })
    .catch((message)=>{
      alert(message);
    })
    setLoading(false)
}
const DeleteApp = async(id)=>
{var data={id: id} 
  await axios.post(`${param.urlService}_app.php?do=del`,data)
  .then(res =>{ console.log(res.data)})
  .catch( (err)=> 
  console.log(err))

}

const GetData= async () => {
   
setLoading(true)
    await axios.get(`${param.urlService}getHelp.php`)
    .then((res)=>{
      if (!res.data.err) {
        setData(res.data);
      } else {
        alert(res.data.err);
      }
    })
    .catch((message) => {
      alert(message);
    });
    setLoading(false)

};

  const handleAddApp=async(app)=>{
    await axios.post(`${param.urlService}${api}?do=add`,{name:app})
    .then(res => {
      alert("Application est ajoute")
      const dat=[...apps];
      dat.push({id:res.data.jData,name:app});
      setApps(dat);
    })
    .catch((message)=>{
      alert(message);
    })
  }

  const handleChangeApp=(val)=>{
    setSelectedApp(val);
  }
  
  useEffect(
    ()=>{ fetchApps(); GetData() ;},[])

  
  
  
    useEffect(() => {
      if (selectedApp !== null && data[selectedApp] !== undefined) {
        setSelectedData(data[selectedApp] || {});
      } else {
        setSelectedData(undefined);
      }
      setValue(selectedApp);
    }, [selectedApp, data]);
    

useEffect(() => {
  setValue(selectedApp);
}, [selectedApp]);

  return (
    <ThemeProvider theme={theme}>
    
      <div className="mainTab">
       <Tooltip title="  Quitter la page ">  
       <Button onClick={setName}> 
       <Logo2  className='helplogo' />
       </Button>
   </Tooltip>
    { (!loading)?<CenteredTabs apps={apps} selectedApp={selectedApp} handleChangeApp={handleChangeApp}   />
    : <Box sx={{ width: '100%' }}>
    <LinearProgress />
   </Box>} 
      <AddAppDialog handleAddApp={handleAddApp}   />
      <EditAppDialog  apps ={apps}  setApps={setApps} data={data} fetchApps={fetchApps} DeleteApp={DeleteApp}/>
      </div>
      
      <DataTable data={data} selectedApp={selectedApp} GetData={GetData} apps={apps} />
    </ThemeProvider>
  );
}
export default ChaimaMain;
