// import React, { useState, useEffect } from 'react';
// import ClickableAway from './FellaClickableAway';
// import axios from 'axios';
// import { getHeader } from '../Gfunc';
// import { Checkbox, FormControlLabel } from '@mui/material';
// import param from '../param.json';

// const EditedCheckbox = ({ api, service, aux, id, defaultValue, onSave }) => {
//     const [value, setValue] = useState(defaultValue);

//     const handleSave = () => {
//         onSave(id, value);
//         updateCheckbox();
//     };

//     const updateCheckbox = async () => {
//         const data = { att: 'name', id: id, val: value };
//         try {
//             const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
//             console.log(response.data);
//             onSave(value);
//         } catch (err) {
//             console.log(err);
//         }
//     };

//     useEffect(() => {
//         const handleKeyPress = (event) => {
//             if (event.key === 'Enter') {
//                 handleSave();
//             }
//         };
//         window.addEventListener('keydown', handleKeyPress);
//         return () => {
//             window.removeEventListener('keydown', handleKeyPress);
//         };
//     }, [handleSave]);

//     return (
//         <ClickableAway onClickAway={handleSave}>
//             <div>
//                 <FormControlLabel
//                     control={<Checkbox checked={defaultValue} onChange={(e) => setValue(e.target.checked)} />}
//                     label="Requis"
//                 />
//             </div>
//         </ClickableAway>
//     );
// };

// export default EditedCheckbox;
//

import React, { useState, useEffect } from 'react';
import ClickableAway from './FellaClickableAway';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import { Checkbox, FormControlLabel } from '@mui/material';
import param from '../param.json';

const EditedCheckbox = ({ api, service, aux, id, defaultValue, onSave, att }) => {
    const [value, setValue] = useState(defaultValue === "1"); // Convertir la valeur en booléen

    const handleSave = () => {
        onSave(id, value ? "1" : "0"); // Envoyer "1" ou "0"
        updateCheckbox();
    };

    const updateCheckbox = async () => {
        const data = { att: att, id: id, val: value ? "1" : "0" }; // Envoyer "1" ou "0"
        try {
            const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
            console.log(response.data);
            onSave(value ? "1" : "0"); // Envoyer "1" ou "0"
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSave();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleSave]);

    return (
        <ClickableAway onClickAway={handleSave}>
            <div>
                <FormControlLabel
                    control={<Checkbox checked={value} onChange={(e) => setValue(e.target.checked)} />}
                    label="Requis"
                />
            </div>
        </ClickableAway>
    );
};

export default EditedCheckbox;

