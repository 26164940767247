import { createTheme } from '@mui/material/styles';



export const myPalette = {
  primary: {
    main: '#1B7EBE',
    light: '#5C9DD1',
    dark: '#004579',
  },
  secondary: {
    main: '#BF2B38',
    light: '#F45D67',
    dark: '#800000',
  },
  background: {
    default: '#ccc',
  },
};


export const theme = createTheme({
  palette: myPalette,
  typography: {
    fontFamily: 'Roboto, sans-serif',
    button: {
      fontSize: "14px",
      fontWeight: "bold",

    },
    h6: {
      fontSize: '16px',
      fontWeight: "500",
    },
  },


  components: {

    MuiTableCell: {
      styleOverrides: {
        root: {
          alignContent: 'left',
          paddingLeft: '20px',
          paddingRight: '0px',
          paddingBottom: '0px',
          paddingTop: '0px',
          minWidth: '50px',
        }

      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: 'none !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: '36px', // Set the desired height here
          fontSize: "16px",
          padding: "0 !important",
        },
        input: {
          // height: '32px !important',
          margin: "2px !important",
          padding: " 6px !important",
          paddingLeft: " 8px !important",
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0",
        },

        paper: {
          // Style the Autocomplete options container
          border: '1px solid #ccc',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 9999,
        },
        option: {
          background: 'white !important',
          '&[data-focus="true"]': {
            backgroundColor: '#eee !important',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        input: {
          padding: "8px !important",
          height: '38px', // Set the desired height here

        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          top: "-12px !important",
        },
        shrink: {
          transform: 'translate(14px, 0px) scale(0.75)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '32px',
          //display : "flex",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: myPalette.primary.dark,
          height: "30px",
          padding: "8px 16px",
          color: "white",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between ',
        },
      },
      title: {
        flex: '1',
        textAlign: 'center',
        margin: 'auto', // Set margin to auto
      },
    },
    MuiDialogContent: {
      styleOverrides: {

        root: {
          background: "transparent",
          padding: "0px 0px",
          color: "black",
          display: 'flex',
          alignItems: 'center',

          // justifyContent: 'space-between',
        },

      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          //background:"blue",
          borderRadius: '8px', // Adjust the border radius for Paper components
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: '0px', // Adjust the border radius for App Bar
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Adjust the border radius for Cards
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          textAlign: 'left !important',
          backgroundColor: '#5C9DD1 !important',
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          border: `1px solid ${myPalette.primary.main}`,

        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '0px !important',
        minWidth: '10px'
        // width :'25% !important',

      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#F4F6F8', // Set a default background color for the entire app
        },
      },
    },
  },
});
