import React, { useEffect, useState } from 'react';
import DraggableDialog from "../modeles/FellaAddApp";
import DraggableDialogApi from "./FellaDraggableDialog";
import CenteredTabs from "./FellaCenterdTabs";
import axios from "axios";
import { getHeader } from "../Gfunc";
import { Box, Button, LinearProgress, Tooltip } from "@mui/material";
import BasicTable from "./FellaTab";
import EditedApp from "./FellaEditableSelect";
import Footer from './FellaFooter';
import { ReactComponent as SvgIconlogo } from '../img/logos/logo2.svg';
import param from '../param.json';
import AddMembers from './FellaAddMembers';


export default function MainPage2({ setName }) {
  const [selectedApp, setSelectedApp] = useState(null);
  const [apps, setApps] = useState([]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [filteredAPI, setFilteredAPI] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const fetchApps = async () => {
    setLoading(true);
    try {
      await axios.get(`${param.urlService}_app.php?do=gets`, { headers: getHeader() })
        .then((response) => {
          setApps(response.data.jData)
          setSelectedApp((response.data.jData.length > 0) ? response.data.jData[0].id : 1)

        })
        .catch((message) => {
          alert(message)
        })
    } catch (error) {
      alert('Erreur lors de la récupération des applications:', error.message);
    }
    setLoading(false);
  };

  const fetchData = async () => {

    try {
      const response = await axios.get(`${param.urlService}getHelp2.php`, { headers: getHeader() });
      setData(response.data);
    } catch (error) {
      alert('Erreur lors de la récupération des données:', error.message);
    }
  };

  const filterAndSetSelectedData = () => {
    setLoading2(true);
    // Check if selectedApp is provided and data is not empty
    if (selectedApp && data.length > 0) {
      // Find the selected app in the data array
      const appData = data.find((item) => item.appId === selectedApp);

      if (appData) {
        let apiData = appData.apis;
        // console.log(appData.apis)
        // Filter the API data if a specific API is selected
        if (filteredAPI) {
          apiData = { [filteredAPI]: apiData[filteredAPI] };
        }
        // Set the selected data initially
        setSelectedData({ apis: apiData });
        // console.log(apiData)
        // If searchTerm is provided, filter the data
        if (searchTerm) {
          const filteredData = {};

          // Iterate over each API
          for (const apiId in apiData) {
            const api = apiData[apiId];
            const filteredServices = {};

            // Check if apiName matches searchTerm
            if (api.apiName && api.apiName.toLowerCase().includes(searchTerm.toLowerCase())) {
              filteredData[apiId] = api;
              continue;
            }

            // Iterate over each service in the API
            for (const serviceId in api.services) {
              const service = api.services[serviceId];

              // Check if serviceName matches searchTerm
              if (service.serviceName && service.serviceName.toLowerCase().includes(searchTerm.toLowerCase())) {
                filteredServices[serviceId] = service;
                continue;
              }

              // Iterate over each parameter in the service
              for (const param of service.params) {
                // Check if paramName matches searchTerm
                if (param.paramName && param.paramName.toLowerCase().includes(searchTerm.toLowerCase())) {
                  // Add the entire service (with all parameters) to filteredServices
                  filteredServices[serviceId] = service;
                  break;
                }
              }
            }

            // If any services match, add the filtered API to filteredData
            if (Object.keys(filteredServices).length > 0) {
              filteredData[apiId] = {
                ...api,
                services: filteredServices
              };
            }
          }

          // Update selectedData with filteredData
          setSelectedData({ apis: filteredData });
        }
      } else {
        setSelectedData(undefined);
      }
    } else {
      setSelectedData(undefined);
    }
    setLoading2(false);
  };

  useEffect(() => {
    fetchApps();
    fetchData();
  }, []);

  useEffect(() => {
    filterAndSetSelectedData();
  }, [selectedApp, data, filteredAPI, searchTerm]);

  // const handleAddAppName = (newAppName, newAppId) => {
  //   const newApps = [...apps, { appId: newAppId, appName: newAppName }];
  //   setApps(newApps);
  //   setSelectedApp(newAppId);
  // };
  const handleAddAppName = (newAppName, newAppId) => {
    const arr = [...apps];
    arr.push({ id: newAppId, name: newAppName });
    setApps(arr);
    setSelectedApp(newAppId)
  };

  const handleChangeApp = (value) => {
    setSelectedApp(value);

  };

  const updateData = (newData) => {
    setData(newData);
  };

  // const updateAppName = (appId, newAppName) => {
  //   setApps((prevApps) =>
  //     prevApps.map((app) => (app.appId === appId ? { ...app, appName: newAppName } : app))
  //   );
  // };
  const updateAppName = (appId, newAppName) => {
    const updatedApps = apps.map((app) =>
      app.id === appId ? { ...app, name: newAppName } : app
    );
    setApps(updatedApps);
  };
  const updateApiName = (apiId, newApiName) => {
    if (selectedData && selectedData.apis) {
      const updatedApis = {
        ...selectedData.apis,
        [apiId]: {
          ...selectedData.apis[apiId],
          apiName: newApiName,
        },
      };
      setSelectedData({ apis: updatedApis });
    }
  };

  // const handleDeleteApp = (appId) => {
  //   setApps((prevApps) => prevApps.filter((app) => app.appId !== appId));
  // };
  const handleDeleteApp = (appId) => {
    setApps((prevApps) => prevApps.filter((app) => app.id !== appId));
  };

  return (
    <div>
      {!loading ? (
        <div className='logo2'>
          <div className="flex-wrapper">
            <Tooltip title="Acceuil : esc">
              <Button onClick={() => setName()}>
                <SvgIconlogo className='svgicon' />
              </Button>
            </Tooltip>
            {apps.length > 0 && (
              <CenteredTabs
                selectedApp={selectedApp}
                apps={apps}
                setApps={setApps}
                handleChangeApp={handleChangeApp}
              />
            )}
            <DraggableDialog handleAddAppName={handleAddAppName} updateApiName={updateApiName} />
            <EditedApp updateAppName={updateAppName} setApps={setApps} handleDeleteApp={handleDeleteApp} />
            <AddMembers apps={apps} setApps={setApps} selectedApp={selectedApp} handleChangeApp={handleChangeApp} />

          </div>
        </div>
      ) : (
        <LinearProgress color="primary" />
      )}

      {!loading ? (
        <Box sx={{ m: "30px" }}>
          {selectedApp && apps.length > 0 && (
            <DraggableDialogApi
              value={selectedApp}
              apps={apps}
              setFilteredAPI={setFilteredAPI}
              setSearchTerm={setSearchTerm}
              updateData={updateData}
              fetchData={fetchData}
            />
          )}
          <BasicTable
            loading={loading2}
            selectedData={selectedData}
            filteredAPI={filteredAPI}
            searchTerm={searchTerm}
            selectedApp={selectedApp}
            updateApiName={updateApiName}
            fetchData={fetchData}
          />
        </Box>
      ) : (
        <LinearProgress color="primary" />
      )}
      <Footer />
    </div>
  );
}