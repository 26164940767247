import React, { useState, useRef, Fragment, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiAccountMultiplePlus, mdiClose, mdiDelete } from '@mdi/js';
import Draggable from 'react-draggable';
import { Autocomplete, DialogActions, TextField, Typography, Button, Dialog, DialogContent, DialogTitle, Paper, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, } from '@mui/material';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import '../style/Fellaglobal.css';
import param from '../param.json'
// import '../css/theme'


function PaperComponent(props) {
    const draggableRef = useRef(null);
    return (
        <Draggable nodeRef={draggableRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={draggableRef} {...props} />
        </Draggable>
    );
}


export default function AddMembers({ selectedApp, apps, setApps }) {
    const [open, setOpen] = useState(false);
    // const [app, setApp] = useState({});
    const [app, setApp] = useState(selectedApp);
    const [name, setName] = useState('');
    const [nickname, setNickname] = useState('');
    const [repEmail, setRepEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [members, setMembers] = useState([]);
    // const [member, setMember] = useState({});
    const [loading, setLoading] = useState(false);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameInputRef = useRef(null);
    const api = '_app.php';

    const getmember = async (id) => {
        setLoading(true);
        await axios.get(`${param.urlService}${api}?do=getmbrs&app=${id}`, { headers: getHeader() })
            .then((response) => {
                setMembers(response.data.jData);
            })
            .catch((message) => {
                alert(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const Addmbr = async () => {
        var data = { app: app.id, nom: name, prenom: nickname, email: repEmail }
        await axios.post(`${param.urlService}${api}?do=addmbr`, data, { headers: getHeader() })
            .then((response) => {
                if (!response.data.err) {
                    setMembers(prevMembers => [...prevMembers, data]);
                    setName('');
                    setNickname('');
                    setRepEmail('');
                } else {
                    alert(response.data.err)
                }
            })
            .catch((message) => {
                alert(message)
            })
    }

    const delMember = async (memberId) => {
        try {
            await axios.post(`${param.urlService}${api}?do=delmbr`, { id: memberId }, { headers: getHeader() });
            const updatedMembers = members.filter(member => member.id !== memberId);
            setMembers(updatedMembers);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = (memberId) => {
        delMember(memberId);
    };

    const handleClickOpen = () => {
        setOpen(true);
        if (apps.length > 0) {
            console.log(apps[0])
            setApp(apps[0]);
        }
        setName("");
        setNickname("");
        setRepEmail("");
        getmember(selectedApp);
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        Addmbr();
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
    };


    const handleAppChange = (event, value) => {

        event.preventDefault();
        if (value && value.id) {
            setApp(value);
            getmember(value.id);
        } else {
            console.error("Invalid value or value.id is null");
        }
    };


    const handleClose = () => {
        setOpen(false);
    };
    const handleChangeEmail = (e) => {
        setRepEmail(e.target.value);
    };

    const handleBlurEmail = () => {
        if (repEmail && !isValidEmail.test(repEmail)) {
            setEmailError("L'email n'est pas valide");
        } else {
            setEmailError("");
        }
    };
    const handleKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'q') {
            handleClickOpen();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Tooltip title='Ajouter des membres à une application : ctrl+q' arrow>
                <IconButton onClick={() => handleClickOpen()}>
                    <Icon path={mdiAccountMultiplePlus} size={1} style={{ color: 'black' }} />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{ style: { height: '650px', width: '1000px' } }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Typography variant="body3"> Ajouter un membre à une application:</Typography>
                    <Tooltip title="Fermer" arrow>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent style={{ display: 'block' }}>
                    <form id='adds' className='form-add-member' onSubmit={handleSubmit}  >
                        <div className="div-member-add">
                            <Autocomplete
                                value={app}
                                onChange={(e, newValue) => handleAppChange(e, newValue)}
                                options={apps}
                                getOptionLabel={(option) => option.name ? option.name : ""}
                                renderInput={(params) => <TextField {...params} label="Applications" />}
                            />
                            <TextField
                                variant="outlined" label='Nom' id="iName" name="iName" value={name} inputRef={nameInputRef}
                                onChange={e => setName(e.target.value)} autoFocus
                                required />
                            <TextField
                                variant="outlined" label='prénom' id="nickname" name="nickname" value={nickname}
                                onChange={e => setNickname(e.target.value)}
                                required
                            />
                            <TextField
                                variant="outlined"
                                label='Email'
                                id="iRepEmail"
                                name="repEmail"
                                value={repEmail}
                                onChange={handleChangeEmail}
                                onBlur={handleBlurEmail}
                                type='email'
                                error={!!emailError}
                                helperText={emailError}
                                required
                            />
                            <Button variant="contained" color="primary" type="submit" >
                                Ajouter
                            </Button>
                        </div>
                    </form>
                    <TableContainer component={Paper} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 230px)', overflowX: 'hidden' }} >
                        <Table aria-label="simple table" className="table" stickyHeader >
                            <TableHead>
                                <TableRow className="table-header">
                                    <Fragment>
                                        <TableCell className=" thead1 smaller-cell tab" style={{ backgroundColor: '#1B7EBE' }}>NOM</TableCell>
                                        <TableCell className=" thead2 smaller-cell tab" style={{ backgroundColor: '#1B7EBE' }}>PRENOM</TableCell>
                                        <TableCell className=" thead3 tab input-cell1" style={{ backgroundColor: '#1B7EBE' }}>EMAIL</TableCell>
                                        <TableCell className=" thead5 smaller-cell1" style={{ backgroundColor: '#1B7EBE' }}></TableCell>
                                    </Fragment>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {members && members.length > 0 ? (
                                            members.map((member, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{member.nom}</TableCell>
                                                    <TableCell>{member.prenom}</TableCell>
                                                    <TableCell>{member.email}</TableCell>
                                                    <TableCell className="smaller-cell1">
                                                        <Tooltip title='Supprimer ce membre' arrow>
                                                            <IconButton onClick={() => handleDelete(member.id)} >
                                                                <Icon path={mdiDelete} size={1} style={{ color: '#BF2B38' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                    Aucun membre trouvé.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>

                {/* <DialogActions>
                    <Button form='adds' variant="contained" color="primary" type="submit">
                        Ajouter
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    );

}