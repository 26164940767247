
import React from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function AutoComplete({ label, id, d }) {
  const [value, setValue] = useState(null)
  const [data, setData] = useState([])
  useEffect(() => {
    if (d !== null && d !== undefined && d.length > 0) {
      setData(d)
      if (label === 'Service' && d) setValue(d[0].name)
    }
  }, [d])

  const handlechange = (event) => {
    setValue(event.target.value)
  }

  return <Autocomplete
    disableClearable
    defaultValue={value}
    onChange={handlechange}
    id={id}
    options={data.map((item) => ({ id: item.id, label: item.name }))}
    loading={data.legnth === 0}
    loadingText={"pas d'options"}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    sx={(label === 'Service') ? { width: '50%' } : { width: '30%' }}
    renderInput={(params) => <TextField {...params} label={label} required />}
  />
    ;
}