import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

const CenteredTabs = ({ apps, selectedApp, handleChangeApp }) => {
  const handleChange = (event, newValue) => {
    handleChangeApp(newValue);
  };

  return (
    <Box sx={{ maxWidth: "calc(100% -150px) !important", bgcolor: "background.paper" ,width:"100%"}}>
      <div>
        <Tabs
          value={selectedApp}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          onChange={handleChange} 
        >
          {apps.map((app) => (
            <Tab
              key={app.id}
              label={app.name}
              value={app.id}
              autoFocus={app.id === selectedApp} 
            />
          ))}
        </Tabs>
      </div>
    </Box>
  );
}

export default CenteredTabs;
