import React from 'react';
import Appversion from './FellaVersioning';
import '../style/Fellatheme'
import { theme } from '../style/Fellatheme';
import { ThemeProvider } from '@mui/material/styles';

const Footer = () => {
  const versionsData = [
    { version: '0.24.1', date: '24.04.2024', description: ['Modification des apis (Fella) ', 'Modification des services (Fella)', 'Modification des paramètres (Fella)', 'Ajout des champs Note In et Note Out dans le dialogue d\'ajout des apis, services et paramètres (Fella)', 'Correctifs design (Fella)'] },
    { version: '0.20.0', date: '15.04.2024', description: ['Affichage de la liste des membres pour chque application (Fella)', 'Ajout d\'un membre a une application (Fella)', 'Supression d\'un membre (Fella) '] },
    { version: '0.17.3', date: '14.04.2024', description: ['Résolution de la problématique de la suppression de service (Fella)', 'Correctifs apportés au dialogue d\'ajout des apis , services et paramètres  (Fella , Imene)', 'Correctifs design (Imene)'] },
    { version: '0.17.0', date: '08.02.2024', description: ['Ajout d\'un bouton de retour à la page d\'accueil (Fella , Imene , Chaima)'] },
    {
      version: '0.16.0', date: '07.02.2024', description: ['Ajout d\'une barre a propos (Fella , Chaima)', 'Affichage de la liste des membres pour chque application (Imene)', 'Ajout d\'une barre de filtrage des donnèes selon les apis (Imene)', 'Ajout d\'une barre de recherche (Imene)', 'Ajout, suppression et modification des applications (Imene)', 'Ajout,  suppression et modification des apis (Imene)', 'Ajout, suppression et modifications des services (Imene)', 'Ajout des paramètres(Imene)']
    },
    { version: '0.8.0', date: '05.02.2024', description: ['Affichage de la liste des services pour chaque application (Fella,Chaima)', 'Ajout d\'une barre de recherche (Fella , Chaima)', 'Ajout d\'une barre de filtrage des donnèes selon les apis (Fella , Chaima)', 'Ajout, suppression et modification des applications (Fella , Chaima)', 'Ajout et suppression des apis (Fella , Chaima)', 'Ajout et suppression des services (Fella , Chaima)', 'Ajout des paramètres (Fella , Chaima)'] },
    { version: '0.1.0', date: '20.12.2023', description: ['Crèation de l\'application'] },

  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="versioning-section">
        <p style={{ flex: '1' }}>Help : {versionsData[0].version}</p>
        <p style={{ flex: '1' }}>powered by DigitArts</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>Last update : {versionsData[0].date}</p>
          <Appversion versionsData={versionsData} style={{ backgroundColor: 'white' }} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Footer;

/*table-layout: fixed !important;*/