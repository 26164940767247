import React from 'react';
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { getHeader } from "../Gfunc";
import param from '../param.json';


export default function AutoCompleteApi({ apis, update }) {

  const [api, setApi] = useState(null);

  useEffect(() => {
    if (apis.length > 0 && apis !== undefined) {
      setApi(apis[0].name)
      async function getData() {
        let service = await getService(apis.find(api => api.name === apis[0].name));
        update(service, apis[0].name);
      }
      getData()
    }
  }, [apis])

  async function getService(ApiId) {
    try {
      const response = await axios.get(param.urlService + `_srv.php?do=gets&api=${ApiId.id}`, { headers: getHeader() });
      return response.data.jData;
    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }
  }

  function onChangeApi(event, value) {
    setApi(value)
    async function getData() {
      let service = await getService(apis.find(api => api.name === value));
      await update(service, value);
    }
    getData();

  };

  return <Autocomplete
    value={api}
    disableClearable
    ListboxComponent="div"
    onChange={onChangeApi}
    loading={apis.legnth === 0}
    loadingText={"pas d'options"}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    options={apis.map((app) => { return app.name })}
    sx={{ width: '100%', mb: 1 }}
    renderInput={(params) => <TextField {...params} label='Api' required />} />;
}

