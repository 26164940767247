import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,

  useTheme,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { mdiClose, mdiPlaylistEdit, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import EditText from "./ChaimaEditedText";
import Tooltip from "@mui/material/Tooltip";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EditAppDialog({ setApps,apps, DeleteApp }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [editingApp, setEditingApp] = React.useState(null);// eslint-disable-line no-unused-vars
  const [editAppId, setEditAppId] = React.useState("");// eslint-disable-line no-unused-vars
  const [editingAppId, setEditingAppId] = React.useState("");// eslint-disable-line no-unused-vars

  const handleClickOpen = (app) => {
    setEditingApp(app);
    setEditAppId(app.id); 
    setOpen(true);
  };
  
  const handleClose = () => {
    setEditingApp(null);
    setEditAppId(null); 
    setOpen(false);
  };
  
  const handleDeleteApp = async (appId) => {
    const confirmed = window.confirm("Voulez-vous vraiment supprimer cette application ?");
    if (confirmed) {
      try {
        await DeleteApp(appId);
        setApps((prevApps) => prevApps.filter((app) => app.id !== appId));
        handleClose();
      } catch (error) {
        console.error("Error deleting app:", error);
        alert("Erreur lors de la suppression de l'application. Veuillez réessayer.");
      }
    }
  };
  const handleEditAppName = (app) => {
    setEditingApp(app); 
    setEditingAppId(app.id); 
  };
  
  const handleSaveAppName = (appId, newAppName) => {
    const updatedApp = apps.map((app) =>
      app.id === appId ? { ...app, name: newAppName } : app
    );
    setApps(updatedApp);
    setEditingApp(null); 
    setEditingAppId(""); 
  };

  return (
    <React.Fragment> <Tooltip title="Modifer les applications" arrow>
      <div  >
        
        <Button className="editbutton"
          onClick={handleClickOpen}
          startIcon={<Icon path={mdiPlaylistEdit} size={1} />}
          
        ></Button> 
      </div></Tooltip>
      <Dialog
        style={{ width: "100%" }}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        
          Veuillez éditer une application
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="edit-grid" style={{ margin:"20px"}}>
            {apps.map((app) => (
              <div key={app.id}  className="appsrec"  > 
                  {/* {editingAppId === app.id ? (          */}
                <EditText
                  app={app}
                  api={"_app.php"}
                  value={app.name}
                  service={"upd"}
                  onSave={handleSaveAppName}
                  id={app.id}
                />
                {/* ) : ( */}
                <span onClick ={() => handleEditAppName(app)} className="editable-icon">
                  <IconButton onClick={() => handleDeleteApp(app.id)}>
                    <Icon
                      path={mdiDelete}
                      size={1}
                      style={{ color: "#1B7EBE", marginLeft: "auto" }}
                    />
                  </IconButton>
                </span>  
                  
              </div>
            ))}
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleSave} type="submit">
            Sauvegarder
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
