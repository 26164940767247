import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxLabels({ requis, setRequis ,disabled}) {
  const handleCheckboxChange = () => {
    if (!disabled) {
    setRequis((prevRequis) => !prevRequis);}
  };

  
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={requis} onChange={handleCheckboxChange} />}
        label="Requis"
        disabled={disabled}
      />
    </FormGroup>
  );
}
