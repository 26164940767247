import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { Button, Box, Dialog, DialogActions, DialogContent, FormControlLabel, Typography, Switch, Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { getHeader } from '../Gfunc';
import Draggable from 'react-draggable';
import axios from 'axios';
import FormControl from './imene_requis'
import MyTextField from './imene_textfeild';
import AutoCompleteApp from './imene_applicationComponent';
import AutoCompleteApi from './imene_apiComponent';
import InputOutputButton from './imene_mybutton';
import { ReactComponent as ApiIcon } from '../assets/icons/api-imene.svg';
import AutoComplete from './imene_myautocomplete';
import param from '../param.json';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`-${index}`}
      aria-labelledby={`-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `${index}`,
    'aria-controls': `${index}`,
  };
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddConfig({ applications, onSave }) {

  const theme = useTheme();
  const [type, setType] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [apis, setApis] = useState([]);
  const [service, setService] = useState([]);
  const [autoF, setautoF] = useState(true)
  const [appSelected, setSelectedApp] = useState(null)
  const [apiSelected, setSelectedApi] = useState()
  const [checked, onCheck] = useState(true);

  const [apps, setApps] = useState(null)

  const [loading, setLoading] = useState(false);

  const handleCheck = () => {
    onCheck(!checked);
  };
  const [inout, setinout] = useState('Entrée');
  const handleClickButton = () => {
    if (inout === 'Entrée') {
      setinout('Sortie');

    } else {
      setinout('Entrée');
    }
  };
  async function postFunc(data, api, serv) {
    try {
      let response = await axios.post(param.urlService + api + `?do=` + serv, data, { headers: getHeader() });

      if (response.data.jData) {
        alert(`Bien été ajoutée.`)
      }
      else {
        alert(response.data.jData)
      }
      setLoading(false)
      return response.data.jData
    }
    catch (error) {
      console.error('Error geting data :', error);
      setLoading(false)

    }

  }
  async function getType() {
    try {
      const response = await axios.get(param.urlService + `_params.php?do=gettyps`, { headers: getHeader() });
      return response.data.jData;
    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }
  }
  async function getFunc(api, service, id) {
    try {
      let response = await axios.get(param.urlService + api + `?do=` + service + `&api=${id}`, { headers: getHeader() });

      if (response.data.jData) {
        return response.data.jData
      }
      else {
        alert(response.data.jData)
      }
    }
    catch (error) {
      console.error('Error geting data :', error);
    }
  }
  useEffect(() => {
    if (applications) {
      if (applications.apps) setApps(applications.apps)
      if (applications.selectedApp) setSelectedApp(applications.selectedApp.name)

    }
  }, [applications])

  const handleSubmit = (event) => {
    setLoading(true)
    async function saveChange() {
      event.preventDefault();
      let data;
      let response;
      const form = event.currentTarget;
      switch (value) {
        case 0:
          let app;
          while (!app) {
            app = apps.find(a => a.name === appSelected)
          }
          data = { app: app.id, name: form.elements.nom.value }
          response = await postFunc(data, '_api.php', 'add');
          const as = await getFunc('_api.php', apiSelected.id);
          setApis(as, app.id)
          break;
        case 1:
          data = { api: apis.find(appi => appi.name === apiSelected).id, name: form.elements.nom.value };
          response = await postFunc(data, '_srv.php', 'add');
          if (response) onSave()
          break;
        default:
          data = {
            srv: service.find(serv => serv.name === form.elements.service.value).id,
            name: form.elements.nom.value,
            typ: type.find(t => t.name === form.elements.type.value).id,
            req: form.elements.requis.checked,
            drct: (form.elements.myButton.innerText === 'ENTRÉE') ? '1' : '2',
            note: form.elements.note.value
          };
          response = await postFunc(data, '_params.php', 'add');
          if (response) onSave()
          break;
      }
      form.elements.nom.value = ''

    }
    saveChange()

  };

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    async function getData() {
      let type = await getType();
      setType(type);
    }
    getData();
  }, []);



  const handleUpdateApi = (value, valeur) => {
    setApis(value)
    setSelectedApp(valeur)
  }
  const handleUpdateService = (value, valeur) => {
    setService(value)
    setSelectedApi(valeur)


  }

  return (
    <Box>
      <ApiIcon onClick={handleClickOpen} className='icon' />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box
              sx={{
                m: 0, bgcolor: 'background.paper',
                width: 500,
              }}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Api" {...a11yProps(0)} />
                  <Tab label="Service" {...a11yProps(1)} />
                  <Tab label="Paramètre" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <div className='div-app'
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <Box sx={{
                  p: 3
                }}>
                  <AutoCompleteApp appname={appSelected} application={apps} update={handleUpdateApi} />
                  <div className='div-config'>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                      < MyTextField text='Nom' id='nom' autoF={autoF} multiline={false} row={1}
                        style={{ width: '450px' }} />
                    </TabPanel>


                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <AutoCompleteApi apis={apis} update={handleUpdateService} />
                      < MyTextField text='Nom' id='nom' autoF={autoF} multiline={false} row={1}
                        style={{ width: '450px' }} />

                    </TabPanel>


                    <TabPanel value={value} index={2} dir={theme.direction}>
                      <div className='div-config'>
                        <AutoCompleteApi apis={apis} update={handleUpdateService} />
                        <AutoComplete label={'Service'} id='service' d={service} />
                      </div>

                      <div className='div-config'>
                        < MyTextField text='Nom' id='nom' autoF={autoF} multiline={false} row={1}
                          style={{ width: '150px' }} />
                        <AutoComplete label={'Type'} id='type' d={type} />
                        {/* <InputOutputButton id='myButton' style={{
                          width: '25%', height: "34px", backgroundColor: '#5C9DD1',
                          color: '#FFFFFF', fontSize: '10', marginTop: '1px', '&:hover': {
                            backgroundColor: '#F45D67',
                            color: '#FFFFFF',
                          },
                        }} /> */}
                        <Button sx={{
                          width: '25%', height: "34px", backgroundColor: '#5C9DD1',
                          color: '#FFFFFF', fontSize: '10', marginTop: '1px', '&:hover': {
                            backgroundColor: '#F45D67',
                            color: '#FFFFFF',
                          },
                        }} onClick={handleClickButton} id='myButton'>
                          {inout}
                        </Button>
                        <FormControlLabel
                          disabled={(inout === 'Sortie')}
                          sx={{ width: '25%', margin: 0 }}
                          checked={(inout === 'Sortie') ? false : checked}
                          onChange={handleCheck}
                          control={<Switch name='requis' />}
                          label={
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontSize={13} fontWeight="bold">
                              requis<span style={{ marginLeft: '4px' }}>*</span>
                            </Typography>}
                        />

                        {/* <FormControl id='requis' /> */}
                      </div>

                      <MyTextField text={'note'} id={'note'} style={{ mt: '10px', width: '450px' }} autoF={false} multiline={true} row={3} />


                    </TabPanel>


                  </div>
                </Box>

              </div>
            </Box>
            <Backdrop
              sx={{ color: '#fff', zIndex: 9999 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </DialogContent>
          <DialogActions>
            <Button type="submit" >Valider</Button>
            <Button onClick={handleClose}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
