import React, { useEffect } from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
export default function MyTextField({ text, id, style, autoF, multiline, row }) {
  //controle taxtfield note
  const [valueTextField, setValueTextField] = useState('');

  const handleChangeTextField = (event) => {
    setValueTextField(event.target.value);
  };

  return (<TextField
    required
    id={id}
    autoFocus={autoF}
    label={text}
    value={valueTextField}
    multiline={multiline}
    rows={row}
    sx={style}
    onChange={handleChangeTextField}
  />);


}