import React, { useEffect } from 'react';
import { ClickAwayListener, TextField, styled, Autocomplete } from "@mui/material";
import { useState } from "react";
import '../style/Fellaglobal.css'
import axios from 'axios';
import param from '../param.json'
import { getHeader } from '../Gfunc';

const StyledAutocomplete = styled(Autocomplete)(() => ({
    outline: 'none',
    width: '100%',
    alignContent: 'space-between !important',
    '&:hover $notchedOutline': {
        border: 'none',
    },
    '&.Mui-focused $notchedOutline': {
        border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '& .MuiAutocomplete-inputRoot': {
        height: '25px !important',
        backgroundColor: 'white',

    },
    '& .MuiOutlinedInput-root': {
        fontSize: '14px !important',
        margin: '0 !important',
        fontStyle: 'bold !important',
        paddingLeft: '0px !important',


    },

    '& .MuiAutocomplete-input': {
        padding: '0px !important',
        margin: '0px !important',
    },

    '&  .MuiAutocomplete-endAdornment': {
        right: '0px !important'
    }


}));

export default function EditableSelect({ api, service, aux, id, defaultValue, options, onSave, handleOptions, val, att, fetchData, selectedApp }) {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [divValue, setDivValue] = useState({ id: val.id, name: val.name });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(val);

    useEffect(() => {
        setSelectedValue(val);
    }, [val]);


    const updApp = async () => {
        const data = { att: att, id: id, val: value.id };
        try {
            const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
            console.log(response.data);
            setDivValue(value);
            fetchData(selectedApp);

        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };

    function handleClickAway() {
        if (value.id !== divValue.id) {
            updApp();
            fetchData(selectedApp);
        }
        setIsEdit(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (value.id !== divValue.id) {
                updApp();
                fetchData(selectedApp);
            }
            setIsEdit(false);
        }
    };

    return (
        isEdit ? (
            <ClickAwayListener onClickAway={handleClickAway}>
                <StyledAutocomplete
                    disableClearable
                    onChange={(e, newValue) => {
                        e.preventDefault();
                        const newValueObject = { id: newValue.id, name: newValue.name };
                        setValue(newValueObject);
                    }}
                    onKeyDown={handleKeyDown}
                    value={value}
                    options={options}
                    loading={options.length === 0}
                    loadingText="Chargement..."
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            required
                        />
                    )}
                />
            </ClickAwayListener>
        ) : (
            isLoading ? (
                <div className="loading-dots"></div>
            ) : (
                <div
                    className='editable'
                    onClick={(e) => {
                        setIsEdit(true);
                        handleOptions();
                        const parent = e.currentTarget.parentNode;
                        parent.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                    }}
                >
                    {divValue.name ? divValue.name : '-----'}
                </div>
            )
        )
    );
}
