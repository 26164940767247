import React, { useEffect } from 'react';

const ClickableAway = ({ onClickAway, children }) => {
  const handleClickAway = (e) => {
    if (!e.target.closest('.click-away')) {
      onClickAway();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickAway]);

  return <div className="click-away">{children}</div>;
};

export default ClickableAway;
