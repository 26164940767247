/* eslint-disable eqeqeq */
import React from 'react';
import { Tooltip, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, MenuItem, Menu, CircularProgress, Paper } from '@mui/material';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef, useState } from 'react';
import { getHeader } from '../Gfunc';
import Icon from '@mdi/react';
import { mdiDeleteOutline, mdiDeleteSweepOutline } from '@mdi/js';
import { mdiFilterMenuOutline } from '@mdi/js';
import EditableComponent from './imene_editableComponentImene';
import { mdiMagnify } from '@mdi/js';
import AddConfig from './imene_addConfig'
import param from '../param.json';

export default function DataTable({ applications }) {

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState('');
  const [apis, setApis] = useState([]);


  const [Apiisedit, setApiIsEDit] = useState(false);
  const [servisedit, setServIsEdit] = useState(false);

  const [ind1, setind1] = useState(null);
  const [ind2, setind2] = useState(null);

  const iconFilterRef = useRef(null)
  const [apps, setApps] = useState(null);


  async function getApis() {
    try {
      const response = await axios.get(param.urlService + `_api.php?do=gets&app=${applications.selectedApp.id}`, { headers: getHeader() });
      return response.data.jData;
    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }
  }
  function updateDataTable(api, name, Apiname) {
    if (api === '_api.php') {
      let appi = apis.filter(a => a.name != name)
      setApis(appi)
      if (name != null) {
        let apiSearch = {}
        for (let appi in searchData) {
          if (appi != name) { apiSearch = { ...apiSearch, [appi]: searchData[appi] } }
        }

        let apiData = {}
        for (let appi in data) {
          if (appi != name) { apiData = { ...apiData, [appi]: data[appi] } }
        }

        let apifilter = {}
        for (let appi in filterData) {
          if (appi != name) { apifilter = { ...apifilter, [appi]: filterData[appi] } }
        }
        setSearchData(apiSearch)
        setData(apiData)
        setFilterData(apifilter)

      }
    }
    else {
      let apiSearch = {}
      for (let appi in searchData) {
        if (appi === Apiname) {
          let servs = searchData[appi]
          let services = {};
          for (let serv in servs) {
            if (name != serv) {
              services = { ...services, [serv]: servs[serv] }
            }
          }
          apiSearch = { ...apiSearch, [appi]: services }
        } else {
          apiSearch = { ...apiSearch, [appi]: searchData[appi] }
        }
      }

      let apiData = {}
      for (let appi in data) {

        if (appi === Apiname) {
          let servs = searchData[appi]
          let services = {};
          for (let serv in servs) {
            if (name != serv) {
              services = { ...services, [serv]: servs[serv] }

            }
          }
          apiData = { ...apiData, [appi]: services }
        } else {
          apiData = { ...apiData, [appi]: apiData[appi] }
        }
      }

      let apifilter = {}
      for (let appi in filterData) {

        if (appi === Apiname) {
          let servs = searchData[appi]
          let services = {};
          for (let serv in servs) {
            if (name != serv) {
              services = { ...services, [serv]: servs[serv] }

            }
          }
          apifilter = { ...apifilter, [appi]: services }
        } else {
          apifilter = { ...apifilter, [appi]: apifilter[appi] }
        }
      }
      setSearchData(apiSearch)
      setData(apiData)
      setFilterData(apifilter)

    }

  }
  async function Delete(api, service, id) {
    let data = { id: id }
    try {
      const response = await axios.post(param.urlService + api + '?do=' + service, data, { headers: getHeader() });
      /* if(response.data.jData) updateDataTable(id);
       else alert(response.data.jData)*/
      return response.data.jData;
    } catch (error) {
      console.error('Error geting data :', error);
    }
  }

  /* async function DelApi(idApi){
     let data = {id:idApi}
     try{
       const response = await axios.post(`https://sarah.digitarts.cloud/help/_api.php?do=del`,data,{headers :getHeader()});
       console.log(response.data) ;
   }
   catch(error)
   {
      console.error('Error geting data :', error);
   }
   }
   async function DelServ(idServ){
     let data = {id:idServ}
     try{
       const response = await axios.post(`https://sarah.digitarts.cloud/help/_srv.php?do=del`,data,{headers :getHeader()});
       console.log(response.data);
       }
   catch(error)
   {
      console.error('Error geting data :', error);
   }
   }*/

  async function getService(idApi) {
    try {
      const response = await axios.get(param.urlService + `_srv.php?do=gets&api=${idApi}`, { headers: getHeader() });
      return response.data.jData;
    }
    catch (error) {
      console.error('Error geting data :', error);
      return [];
    }
  }

  async function getData() {

    const response = await axios.get(param.urlService + 'getHelp.php', { headers: getHeader() });
    const data = Object.entries(response.data).filter(([key, value]) => key == applications.selectedApp.id).map(([key, value]) => value.api);
    return data[0];
  }

  useEffect(() => {
    //setLoading(true)
    async function getD() {
      let data = await getData();
      setData(data);
      setFilterData(data);
      setSearchData(data);
      setSearch('');
      // iconFilterRef.current.classList = 'blackIcon';
      let apisData = await getApis();
      setApis(apisData);
    }
    if (applications) {
      if (applications.apps && applications.selectedApp) {
        getD();
        setApps(applications)
      }
    }
  }, [applications]);

  function handleChange(value) {
    if (value != 'Tout') {
      setFilterData({ [value]: data[value] });
      setSearchData({ [value]: data[value] });
      setSearch('')
      setAnchorEl(null);
      iconFilterRef.current.classList = 'redIcon'

    } else {
      setFilterData(data)
      setSearchData(data)
      setSearch('')
      setAnchorEl(null);
      iconFilterRef.current.classList = 'blackIcon'

    }
  };

  function handleChangeTextField(event) {
    setSearch(event.target.value);
    if (event.target.value != null) {
      //recherche dans api
      let api = {}
      for (let appi in filterData) {
        if (appi.toLowerCase().includes(event.target.value.toLowerCase())) {
          api = { ...api, [appi]: data[appi] }
        } else {
          let servs = filterData[appi]
          let service = {}
          for (let serv in servs) {
            if (serv.toLowerCase().includes(event.target.value.toLowerCase())) {
              service = { ...service, [serv]: servs[serv] }
            }
            else {
              let params = servs[serv]
              let parametre = {}
              for (let p in params) {
                if (p.toLowerCase().includes(event.target.value.toLowerCase()) || params[p].typ.toLowerCase().includes(event.target.value.toLowerCase())) {
                  parametre = { ...parametre, [p]: params[p] }
                }
              }
              if (Object.keys(parametre).length !== 0) { service = { ...service, [serv]: parametre } }
            }

          }
          if (Object.keys(service).length !== 0) { api = { ...api, [appi]: service } }
        }
      }
      setSearchData(api)

    } else {
      setSearchData(data)
    }

  };
  async function handleDelete(api, Apiname, Servname) {
    const userResponse = window.confirm(`Voulez-vous vraiment supprimer ce service ?`);
    if (userResponse) {
      let ApiId
      let Api = apis.find(apii => apii.name === Apiname)
      if (Api !== null) ApiId = Api.id
      let elementId;
      if (api === '_api.php') elementId = ApiId
      else {
        let ser = await getService(ApiId);
        console.log(ser);
        if (ser.length > 0) elementId = ser.find(s => s.name === Servname).id
      }
      let response = await Delete(api, 'del', elementId);
      //updateDataTable(api,Servname,Apiname)
      if (response) {
        if (api === '_api.php') updateDataTable(api, Apiname)
        else updateDataTable(api, Servname, Apiname)

      }

    }

  }
  /* function handleDelApi(api){
     let ApiId = apis.find(apii => apii.name === api).id
     const userResponse = window.confirm(`Voulez-vous vraiment supprimerle api ?`);
     if(userResponse){
        DelApi(ApiId);
     }
     };
   
   async function handleDelServ(api,serv){
     const userResponse = window.confirm(`Voulez-vous vraiment supprimer ce service ?`);
     
     if(userResponse){
       
         let ApiId = apis.find(apii => apii.name === api).id
         let service = await getService(ApiId);
         let servId = service.find(s => s.name === serv).id
       if(servId !==null && servId !== undefined){
               DelServ(servId);
       }
     }  
   }*/
  const ITEM_HEIGHT = 48;
  const handleClickApi = (index) => {
    setApiIsEDit(true)
    setind1(index)
  }
  const handleClickServ = (index, indexApi) => {
    setServIsEdit(true)
    setind2({ serv: index, api: indexApi })
  }
  const cancel = () => {
    setApiIsEDit(true)
    setServIsEdit(true)
    setind1({})
    setind2({})
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const openApiMenu = Boolean(anchorEl);
  const handleClickApiMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseApiMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateApiName = (name, id, newName) => {
    let appi = apis.map(item => (item.id === id) ? { id: id, name: newName } : item)
    setApis(appi)
    if (newName != null) {
      let apiSearch = {}
      for (let appi in searchData) {

        if (appi === name) {
          apiSearch = { ...apiSearch, [newName]: searchData[appi] }
        } else {
          apiSearch = { ...apiSearch, [appi]: searchData[appi] }
        }
      }

      let apiData = {}
      for (let appi in data) {

        if (appi === name) {
          apiData = { ...apiData, [newName]: data[appi] }
        } else {
          apiData = { ...apiData, [appi]: data[appi] }
        }
      }

      let apifilter = {}
      for (let appi in filterData) {

        if (appi === name) {
          apifilter = { ...apifilter, [newName]: filterData[appi] }
        } else {
          apifilter = { ...apifilter, [appi]: filterData[appi] }
        }
      }
      setSearchData(apiSearch)
      setData(apiData)
      setFilterData(apifilter)


    }

  }
  const setUpdateNameService = (name, id, newName) => {
    if (newName != null) {
      let apiSearch = {}
      for (let appi in searchData) {

        if (appi === name) {
          let servs = searchData[appi]
          let services = {};
          for (let serv in servs) {
            if (id === serv) {
              services = { ...services, [newName]: servs[serv] }

            } else {
              services = { ...services, [serv]: servs[serv] }
            }
          }
          apiSearch = { ...apiSearch, [appi]: services }
        } else {
          apiSearch = { ...apiSearch, [appi]: searchData[appi] }
        }
      }

      let apiData = {}
      for (let appi in data) {

        if (appi === name) {
          let servs = data[appi]
          let services = {};
          for (let serv in servs) {
            if (id === serv) {
              services = { ...services, [newName]: servs[serv] }

            } else {
              services = { ...services, [serv]: servs[serv] }
            }
          }
          apiData = { ...apiData, [appi]: services }
        } else {
          apiData = { ...apiData, [appi]: data[appi] }
        }
      }

      let apifilter = {}
      for (let appi in filterData) {

        if (appi === name) {
          let servs = filterData[appi]
          let services = {};
          for (let serv in servs) {
            if (id === serv) {
              services = { ...services, [newName]: servs[serv] }

            } else {
              services = { ...services, [serv]: servs[serv] }
            }
          }
          apifilter = { ...apifilter, [appi]: services }
        } else {
          apifilter = { ...apifilter, [appi]: filterData[appi] }
        }
      }
      setSearchData(apiSearch)
      setData(apiData)
      setFilterData(apifilter)


    }
  }

  function handleSave() {
    async function getD() {
      let data = await getData();
      setData(data);
      setFilterData(data);
      setSearchData(data);
    }
    if (applications.apps != null && applications.selectedApp != null) {
      getD();
      setApps(applications)
    }

  }
  return (
    <Box sx={{ pl: 1, pr: 1, pt: 5, pb: 5, background: '#fcfcfc' }}>

      <div className='div-config'>
        <Icon path={mdiMagnify} size={1} style={{ fontSize: '25px', marginTop: '20px' }} />
        <TextField
          sx={{ width: '20%' }}
          label='Rehercher'
          value={search}
          onChange={handleChangeTextField}
          variant="standard"
        />

        <IconButton
          classes={{ root: 'iconButtonRoot' }}
          id="long-button"
          aria-controls={openApiMenu ? 'long-menu' : undefined}
          aria-expanded={openApiMenu ? 'true' : undefined}
          aria-haspopup="true"
          edge="start"
          onClick={handleClickApiMenu}
          menuprops={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            getContentAnchorEl: null, // Prevents scroll to top when menu opens
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '60ch',
              },
            },
          }}
        >
          <Icon
            ref={iconFilterRef}
            path={mdiFilterMenuOutline}
            size={'38px'}
            className='icon'
          />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={openApiMenu}
          onClose={handleCloseApiMenu}
        > <MenuItem key='Tout' onClick={() => handleChange('Tout')}>
            Tout
          </MenuItem>
          {
            (data != null) ? Object.keys(data).map((key) => (
              <MenuItem key={key} onClick={() => handleChange(key)}>
                {key}
              </MenuItem>
            ))
              : null}
        </Menu>
        <AddConfig applications={apps} onSave={handleSave} />
      </div>
      {(apps == null)
        ? <Box className='boxCircularProgress'>
          <CircularProgress color="primary" variant="indeterminate" sx={{ justifyContent: 'flex-center' }} />
        </Box>
        :

        <TableContainer component={Paper}>
          <Box sx={{
            overflowY: 'scroll', maxHeight: 'calc(100vh - 190px)', overflowX: 'hidden',
          }}>
            <Table stickyHeader >
              <TableHead >
                <TableRow className='tablerow'>
                  <TableCell className='headclass'>Api</TableCell>
                  <TableCell className='headclass'>Services</TableCell>
                  <TableCell className='headclass'>Input</TableCell>
                  <TableCell className='headclass'>Output</TableCell>
                  <TableCell className='headclass-small' sx={{ backgroundColor: '#1B7EBE !important' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {(searchData != null)
                  ? Object.entries(searchData).map(([key, value], index1) => Object.entries(value).map(([key1, value1], index2) => {
                    return <TableRow key={`${key}-${key1}`}>
                      {(index2 === 0)
                        ? <TableCell className='api' rowSpan={Object.keys(value).length}>
                          <div className='div-flex'>
                            {(Apiisedit && ind1 === index1)
                              ? <div className='edit-component'><EditableComponent
                                name={key}
                                id={apis.find(api => api.name === key).id}
                                setUpdateName={handleUpdateApiName}
                                setIsedit={() => cancel()}
                                api={'_api.php'}
                                service={'upd'}
                                aux={`&app=${apps.selectedApp.id}`} /></div>
                              : <div className='edit-div' onClick={() => handleClickApi(index1)}>{key}</div>}
                            <Tooltip title="Effacer l'api"><IconButton className='deleteApiButton' onClick={() => handleDelete('_api.php', key, '')} edge="start" sx={{ height: '40px', margin: '0px' }}>
                              <Icon path={mdiDeleteSweepOutline} size={'35px'} style={{ color: '#BF2B38', marginLeft: '3px' }} />
                            </IconButton></Tooltip>
                          </div>
                        </TableCell>
                        : null}
                      <TableCell>
                        <>
                          {(servisedit && ind2.serv === index2 && ind2.api === index1)
                            ? <EditableComponent
                              name={key1}
                              id={getService}
                              setIsedit={() => cancel()}
                              api={'_srv.php'}
                              service={'upd'}
                              aux={`&api=${apis.find(api => api.name === key).id}`}
                              setUpdateName={setUpdateNameService}
                              sub={{ idApi: apis.find(api => api.name === key), serv: key1 }}
                            />
                            : <div className='edit-div' onClick={() => handleClickServ(index2, index1)}>{key1}</div>}
                        </></TableCell>
                      <TableCell className='inputclass'>{Object.entries(value1).filter(([k, v]) => v.direction === '1').map(([k, v]) =>
                        <spam key={`${key}-${key1}-${k}`}><span style={{ paddingRight: '5px', color: 'red', fontWeight: 'bold' }}>{v.typ}</span>
                          <span>{k}</span>
                          <span style={{ fontWeight: 'bolder' }}>{v.must ? '*' : null}</span>
                        </spam>).map((item, index, array) => (
                          <React.Fragment key={index}>
                            {item}
                            {index !== array.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                      </TableCell>
                      <TableCell className='inputclass' >{Object.entries(value1).filter(([k, v]) => v.direction === '2').map(([k, v]) =>

                        <span key={`${key}-${key1}-${key1}`}>
                          <span style={{ paddingRight: '5px', color: 'red', fontWeight: 'bold' }}>{v.typ}</span>
                          <span>{k}</span>
                          <span style={{ fontWeight: '800', paddingRight: '5px' }}>{v.must ? '*' : null}</span>
                        </span>).map((item, index, array) => (
                          <React.Fragment key={index}>
                            {item}
                            {index !== array.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                      </TableCell>


                      <TableCell className='headclass-small' key='small-key'>
                        <Tooltip title="Effacer le service" arrow>
                          <IconButton edge="start" onClick={() => handleDelete('_srv.php', key, key1)}>
                            <Icon path={mdiDeleteOutline} size={'30px'} style={{ color: '#BF2B38', marginLeft: '3px' }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  }
                  ))
                  : <TableRow><TableCell key="no-data-row" colSpan={5} sx={{ textAlign: 'center !important' }}>Aucune donnée à afficher pour le moment.</TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </Box>

        </TableContainer>

      }
    </Box>

  );
}
