import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import './style/Fellaglobal.css'
import { theme } from './style/Fellatheme';
import MainPage from './components/FellamainPage';


function FellaMain({setName}) {
  return (
    <ThemeProvider theme={theme}>
     <div style={{display:"block"}}>
        <MainPage setName={setName}/>   
    </div>
    </ThemeProvider>

  );
}

export default FellaMain;
