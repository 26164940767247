import React, { useState, useEffect } from 'react';
import ClickableAway from './FellaClickableAway';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import param from '../param.json';

const EditedRadioButton = ({ api, service, aux, id, defaultValue, onSave, att }) => {
    const [value, setValue] = useState(defaultValue === "1" ? "Input" : "Output");

    const handleSave = () => {
        onSave(id, value === "Input" ? "1" : "2");
        updateRadioButton();
    };

    const updateRadioButton = async () => {
        const data = { att: att, id: id, val: value === "Input" ? "1" : "2" };
        try {
            const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
            console.log(response.data);
            onSave(value === "Input" ? "1" : "2");
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleSave();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleSave]);

    return (
        <ClickableAway onClickAway={handleSave}>
            <div>
                <RadioGroup
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="Input"
                        control={<Radio />}
                        label="In"
                    />
                    <FormControlLabel
                        value="Output"
                        control={<Radio />}
                        label="Out"
                    />
                </RadioGroup>
            </div>
        </ClickableAway>
    );
};

export default EditedRadioButton;
