import React, { useEffect, useState } from 'react';
import { Paper, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiClose, mdiExclamation } from '@mdi/js';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Appversion({versionsData}) {
  const [open, setOpen] = useState(false);

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'x') {
      handleClickOpen();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tooltip title="A propos : ctrl+x" arrow>
        <IconButton onClick={handleClickOpen} >
          <Icon path={mdiExclamation} size={0.9} style={{ color: 'black',backgroundColor:'white', borderRadius:'10px' }} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{ style: { height: '750px', width: '800px' } }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Historique des versions
          <Tooltip title="Fermer" arrow>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <Icon path={mdiClose} size={1} />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>    
          <TableContainer  style={{ borderRadius: '10px' ,marginTop:'20px', padding:'2px'}}>
            <Table aria-label="simple table" className="table3" stickyHeader >
              <TableHead >
                <TableRow className="table-header">
                  <TableCell className="smaller-cell3 tab  " style={{ backgroundColor: '#1B7EBE' }}>VERSION</TableCell>
                  <TableCell className="smaller-cell3 tab " style={{ backgroundColor: '#1B7EBE' }}>DATE</TableCell>
                  <TableCell className="tab " style={{ backgroundColor: '#1B7EBE' }}>DESCRIPTION</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {versionsData.map((versionInfo, index) => (
                  <TableRow key={index} >
                    <TableCell className="smaller-cell3  version-cellf"  >{versionInfo.version}</TableCell>
                    <TableCell className="smaller-cell3  date-cell"  >{versionInfo.date}</TableCell>
                    <TableCell className="description-cell" >
                      <ul style={{paddingLeft:'20px'}}>
                        {versionInfo.description.map((desc, descIndex) => (
                          <li key={descIndex}>{desc} </li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
