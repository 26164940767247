import * as React from 'react';
import { useRef, useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, TextField, LinearProgress, Box } from '@mui/material';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import { mdiDeleteOutline } from '@mdi/js';
import { mdiPlus } from '@mdi/js';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { mdiPlaylistEdit } from '@mdi/js';
import EditableComponent from './imene_editableComponentImene';
import Draggable from 'react-draggable';
import param from '../param.json';



function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ListApp({ onselected, applications, loding }) {

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ind, setind] = useState(null);
  const [application, setApplications] = useState([]);
  const [name, setName] = useState('');
  const [load, setLoad] = useState(true);
  const ButtonGroupRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [notScroll, setNotScroll] = useState(false)

  useEffect(() => { setApplications(applications) }, [applications]);
  useEffect(() => { setLoad(loding) }, [loding]);
  useEffect(() => {
    if (application) {
      onselected(application[0])
    }
  }, [application])

  const handleChangeName = (event) => { setName(event.target.value); };
  //post addApp
  async function addApp() {
    const data = { name: name }
    try {
      const response = await axios.post(param.urlService + '_app.php?do=add', data, { headers: getHeader() });
      if (response.data.jData) {
        alert(`l'application est bien été ajoutée`)
        let appps = [{ name: name, id: response.data.jData }, ...application]
        setApplications(appps)
      }
      else { alert(response.data.jData) }
    } catch (error) {
      alert(error);
    }
  };

  /*async function getApp(){
    try{
         const response = await axios.get('https://sarah.digitarts.cloud/help/_app.php?do=gets',{headers :getHeader()});
         setLoad(false)
         return (response.data.jData);
         
    }
    catch(error)
    {
        console.error('Error geting data :', error);
        return [];
    }
 
}*/
  /* useEffect (() => {
      async function getData(){
      let jdata = await getApp(()=>null);
      setApplication(jdata);
      onselected(jdata[0]);
     }
     getData();
     
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[]);*/


  const handleSubmit = (event) => {
    event.preventDefault();
    addApp();
    handleClose();
  };
  const handleSubmitEdit = (event) => {
    event.preventDefault();
    handleCloseEdit();
  }
  const onClick = (newapp, index) => {
    const element = ButtonGroupRef.current.children[index];
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    setActiveIndex(index)
    onselected(newapp)

  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const butttonEdit = (key) => {
    if (key === 'input') {
      setIsEdit(false)
    } else {
      setIsEdit(true)
      setind(key)
    }

  }
  async function delApp(id) {
    let data = { id: id };
    try {
      const response = await axios.post(param.urlService + '_app.php?do=del', data, { headers: getHeader() });
      if (response.data.jData) {
        let updatedApplications = application.filter(app => app.id !== id);
        setApplications(updatedApplications);
        //alert(`L'application a bien été supprimée.`)
      } else {
        alert(response.data.jData)
      }
    } catch (error) { console.error('Error posting data:', error); }
  };
  const handleClickDel = (app) => {

    const userResponse = window.confirm("Voulez-vous vraiment supprimer cette application ?");
    if (userResponse) {
      delApp(app.id)
    }
  }

  const handleScrollLeft = () => {
    if (ButtonGroupRef.current) {
      ButtonGroupRef.current.scrollBy({
        left: -100,
        behavior: 'smooth',
      });
    }
  };
  const handleScrollRight = () => {
    if (ButtonGroupRef.current) {
      ButtonGroupRef.current.scrollBy({
        left: +100,
        behavior: 'smooth',
      });

    }
  };
  const handleUpdatename = (name, id, newName) => {
    const newAplication = application.map(item => (item.id === id) ? { id: id, name: newName } : item)
    setApplications(newAplication)
  }
  useEffect(() => {
    const handleScroll = () => {
      if (ButtonGroupRef.current.scrollLeft === 0) {
        setNotScroll(false);
      } else {
        setNotScroll(true);
      }
    };

    if (ButtonGroupRef.current) {
      ButtonGroupRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (ButtonGroupRef.current) {
        ButtonGroupRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  });

  return <><div className='flex-wrapperI'>
    {(load)
      ? <Box sx={{ width: '100%', margin: 'auto' }}>
        <LinearProgress />
        <LinearProgress />
      </Box>
      : <>
        {notScroll &&
          <IconButton className='scrollLeft' onClick={handleScrollLeft}>
            <Icon path={mdiChevronLeft} size={'30px'} style={{ color: 'black', marginLeft: '1px' }} />
          </IconButton>

        }
        <div ref={ButtonGroupRef} className='groupebutton'>
          {application.map((app, index) => (
            <div className={activeIndex === index ? 'active' : 'button'}
              size='auto' key={index} onClick={() => onClick(app, index)}
            >
              {app.name}
            </div>
          ))}

        </div>
        <IconButton className='scrollRight' onClick={handleScrollRight}>
          <Icon path={mdiChevronRight} size={'30px'} style={{ color: 'black', marginLeft: '1px' }} />
        </IconButton>
      </>
    } <Tooltip title="Ajouter une App">
      <IconButton edge="start" onClick={handleClickOpen} sx={{ borderRadius: 100, height: '32px' }}>
        <Icon path={mdiPlus} size={'25px'} style={{ color: 'black', marginLeft: '1px', marginRight: '0px', marginTop: '7px' }} />
      </IconButton></Tooltip> <Tooltip title="Modifier une Application">
      <IconButton edge="start" onClick={handleClickEdit} sx={{ borderRadius: 100, height: '32px' }}>
        <Icon path={mdiPlaylistEdit} size={'30px'} style={{ color: 'black', marginLeft: '1px', marginTop: '7px' }} />
      </IconButton></Tooltip>

    <Dialog
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="draggable-dialog-title"
      PaperProps={{ style: { width: '50%' } }}
      PaperComponent={PaperComponent}
    >
      <form onSubmit={handleSubmitEdit}>
        <DialogContent className='appTable'>
          <div className={'div'} >
            {application.map((app, index) => (
              <Box key={index} className='box'>
                {(isEdit && index === ind)
                  ? <EditableComponent
                    name={app.name}
                    id={app.id}
                    setUpdateName={handleUpdatename}
                    setIsedit={() => butttonEdit('input')}
                    api={'_app.php'}
                    service={'upd'}
                    aux={`&app=${app.id}`} />

                  : <> <Button size='auto' key={index} sx={{ mr: 1 }} onClick={() => butttonEdit(index)} className='appname'>
                    {app.name}
                  </Button>
                    <Tooltip title="Effacer l'application">
                      <IconButton edge="start" color='#1B7EBE' onClick={() => handleClickDel(app)} >
                        <Icon path={mdiDeleteOutline} size={0.9} style={{ color: '#BF2B38' }} />
                      </IconButton>
                    </Tooltip></>
                }

              </Box>
            ))}
          </div>
        </DialogContent>
      </form>
    </Dialog>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      PaperProps={{ style: { width: '30%' } }}
      PaperComponent={PaperComponent}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography>Ajouter une application</Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content'>
          <TextField
            sx={{ margin: 2 }}
            label={"Nom de l'application"}
            value={name}
            onChange={handleChangeName}
            variant="outlined"
            fullWidth
          />
          <br />
          <br />

        </DialogContent>
        <DialogActions>
          <Button type="submit">Valider</Button>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>

        </DialogActions>
      </form>
    </Dialog>

  </div>
  </>;
}