import React, { useState } from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import axios from "axios";
import {styled} from "@mui/material";
import { TextField } from "@mui/material";
import param from "../param.json";
export default function EditText({ value, onSave, api, service,aux,id}) {
  const [textValue, setTextValue] = useState(value || "");
 
  const STextField = styled(TextField)(() => ({
    '& .MuiOutlinedInput-root': { 
      '& fieldset': {
        border: 'none', 
      },
      
      '& .css-7fwpgj-MuiInputBase-input-MuiOutlinedInput-input': {
        paddingLeft: '1ch !important', 
        background: 'white', 
        border : '1px solid #F5F4F3',
        borderRadius :'2%',
        height :'auto !important',
        width:'auto !important'
  },
  },
  }));

 

  const handleSaveName = () => {
    if (textValue !== value) {
      updApp();
    }
  };
  const handleClickAway = () => {
   handleSaveName()
    
    
  };

  const updApp = async () => {
    const data = { att: 'name', id: id, val: textValue };
    try {
      const response = await axios.post(
        `${param.urlService}${api}?do=${service}${aux !== undefined ? `${aux}` : ''}`,
        data
      );
      
      if(response.data.jData) {
      onSave(id, textValue);
      }
    } catch (err) {
    console.log(err);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} >
      
      <STextField
        type="text"
        value={textValue}
        onChange={(e)=> setTextValue(e.target.value)}
        className="editable-input"
        autoFocus 
      /> 
    </ClickAwayListener>
  );
}
