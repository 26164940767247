import { createTheme } from '@mui/material/styles';



export const myPalette = {
  primary: {
    main: '#1B7EBE',
    light: '#5C9DD1',
    //dark: '#004579',
    dark: '#005A8D',
  },
  secondary: {
    main: '#BF2B38',
    light: '#F45D67',
    dark: '#800000',
  },
  background: {
    default: '#F4F6F8',
  },
};


export const theme = createTheme({
    palette: myPalette,
    typography: {
      fontFamily: 'Roboto, sans-serif',
      button: {
        fontSize: 13,
        fontWeight: "bold",
    
      },
      h6: {
        fontSize: '16px',
        fontWeight: "500",
      },
    },

  
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: 'none', // Adjust the max width as needed
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
           // height: '36px', // Set the desired height here
            fontSize:"16px",
            padding:"0 !important",
          },
          input:{
           // height: '32px !important',
            margin:"2px !important",
            padding:" 2px 0px !important",
            paddingLeft:" 8px !important",
          }
        },
      },
      MuiAutocomplete:{
        styleOverrides: {
          root: {
            padding:"0",
          },
          
            paper: {
              // Style the Autocomplete options container
              border: '1px solid #ccc',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
             
            },
            option: {
              background:'white !important',
              '&[data-focus="true"]': {
                backgroundColor: '#eee !important',
              },
            },
        },
      },    
      MuiSelect: {
        styleOverrides: {
          select: {
            height: '38px', // Set the desired height here
          },
        },
      },
      /*MuiTextField: {
        styleOverrides: {
            input: {
              padding:"0px !important",
              height: '38px', // Set the desired height here
              
            },
        },
      },*/
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            top:"-12px !important",
          },
          shrink: {
            transform: 'translate(14px, 0px) scale(0.75)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: '32px', // Adjust the height of buttons
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background:myPalette.primary.dark,
            height:"30px",
            padding:"8px 16px",
            color:"white",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between ',
          },
        },
        title: {
          flex: '1',
          textAlign: 'center',
          margin: 'auto', // Set margin to auto
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            background:"white",
            padding:"16px 24px !important",
            color:"black",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between !important',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            //background:"blue",
            borderRadius: '8px', // Adjust the border radius for Paper components
          },
        },
      },
     
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: '8px', // Adjust the border radius for App Bar
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px', // Adjust the border radius for Cards
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '9px', 
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
         root: {
          display: 'grid' ,
          gridtemplatecolumns:' repeat(auto-fit, minmax(200px, 1fr)) ',
          gap: '5px ',
          border:'1px solid #red !important' ,
          padding:'8px' , 
          
         },
         }, 
        },
     
      MuiButtonBase: {
        styleOverrides: {
          root: {
            MuiButton:{
              root:{
                lineheight: '0 !important', 
                alignItems:'center',
               
              }
            } 
           
          },
        },
      },
   
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          width :'25% !important',
          height:'80px !important'
        },
      },
      },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#F4F6F8', // Set a default background color for the entire app
          },
        },
      },
    },
    });
