import React, { useEffect, useState} from 'react';
import { Paper, Dialog, DialogContent,  DialogTitle, IconButton, Tooltip } from '@mui/material';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiClose, mdiPlaylistEdit} from '@mdi/js';
import EditedTab from './FellaEditableTab';



function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function EditedApp(props) {
  const [open, setOpen] = useState(false);
  const { updateAppName} = props;
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'b') {
      handleClickOpen(); 
    }
  };
 
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <>
      <Tooltip title="Editer la liste des applications: Ctrl+b" arrow>
        <IconButton onClick={handleClickOpen} > 
          <Icon  path={mdiPlaylistEdit} size={1}  style={{ color: 'black' }} />
        </IconButton>
      </Tooltip>
      
      
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{style:{height:'400px',width:'800px'}}}
  
        >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

          Editer la liste des applications
            <Tooltip title="Fermer" arrow>
          <IconButton  edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <Icon path={mdiClose} size={1} />
            </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent>    
          <EditedTab updateAppName={updateAppName} handleDeleteApp={props.handleDeleteApp} /> 
        </DialogContent>
      </Dialog>
    </>
  );
}