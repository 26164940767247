import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";

export default function Direction({ direction, setDirection }) {
  return (
    <FormControl>
      <Grid container alignItems="center">
        <Grid item>
          <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "16px" }}>Direction</FormLabel>
        </Grid>
        <Grid item>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={direction} 
            onChange={(e) => setDirection(e.target.value)} 
          >
            <FormControlLabel value="1" control={<Radio />} label="In" />
            <FormControlLabel value="2" control={<Radio />} label="Out" />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
}
