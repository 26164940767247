import React, { useEffect, useState } from "react";
import {Box, Tab,Tabs }from "@mui/material";




  export default function CenteredTabs({apps,handleChangeApp,selectedApp, handlesetnameapp}) {
    const [value, setValue] = useState(apps[0].id);
    
    const handleChange = (event, newValue) => {
      setValue(newValue);   
      handleChangeApp(newValue)
    };
   

    useEffect(()=>{
       setValue(selectedApp);
    },[selectedApp]);
  
    return (
        <Box
         sx={{ maxWidth: "calc(100% - 110px)"
          }}>

            
            {/* #f0f0f0  , bgcolor: '#E5E5E5' */}
          <Tabs value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example">
             {apps.map((app, index) => (
             <Tab key={app.id} label={app.name} value={app.id} autoFocus={app.id===selectedApp}  handlesetnameapp={handlesetnameapp}/>
            ))}
          </Tabs>
        </Box>
    );
             }