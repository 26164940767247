import React, { useEffect, useState} from 'react';
import { Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle,TextField, IconButton, Tooltip, Box } from '@mui/material';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import param from '../param.json'

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddApp(props) {
  const [open, setOpen] = useState(false);
  const [newAppName, setNewAppName] = useState("");
  var api='_app.php'


  const Addapp =async ()=>{
   var data={name:newAppName}
   await axios.post(`${param.urlService}${api}?do=add`,data,{headers:getHeader()})
                     .then((response)=>{
                        console.log(response)
                        if(!response.data.err){
                        props.handleAddAppName(newAppName,response.data.jData);
                        setOpen(false)
                      } else (alert(response.data.err))
                      })
                      .catch((message)=>{
                        alert(message)
                      })
  }

  const handleClickOpen = () => {
    setOpen(true);
    setNewAppName(""); 

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddAdd = () => {
    if (newAppName) {
      Addapp();
    }
  };

 const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'm') {
      handleClickOpen(); 
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);







 
  
 
  return (
    <>
      <Tooltip title="Ajouter une application: Ctrl+m" arrow>
        <IconButton onClick={handleClickOpen} >
          <Icon path={mdiPlus} size={1}  style={{ color: 'black' }} />
        </IconButton>
      </Tooltip>
      
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{style:{height:'161px'}}}
      >
        
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Ajouter le nom de l'application
        </DialogTitle>
        <DialogContent>
          <Box style={{width:'400px',paddingTop:'25px'}} >
            <TextField
              variant='outlined'
              fullWidth
              autoFocus
              label="Nom de l'application"
              placeholder="Ex: BOUTIQA"
              value={newAppName}
              onChange={(e) => setNewAppName(e.target.value)}
            />         
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color='primary' onClick={()=>handleAddAdd()}>Ajouter</Button>
          <Button variant="contained"  color='primary' onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
      
    </>
    
  );
}