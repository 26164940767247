import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Autocomplete, IconButton } from "@mui/material";
import AddAllDialog from "./ChaimaAddAllDialog";
import Icon from "@mdi/react";
import { mdiMagnify, mdiDeleteOutline } from "@mdi/js";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
// import ChaimaFooter from "./ChaimaBarVersion";
import param from "../param.json";
var Api = "_api.php";
var srv = "_srv.php";
export default function DataTable({ data = {}, selectedApp, GetData, apps, updateData }) {
  const [searchText, setSearchText] = useState("");
  const [selectedApi, setSelectedApi] = useState("");
  const [filteredApi, setFilteredApi] = useState("");// eslint-disable-line no-unused-vars

  const [api, setApi] = useState({});
  const [services, setServices] = useState([]);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") GetData();
  };

  const handleApiFilterChange = (event, value) => {
    setSelectedApi(value === null ? "" : value);
    setSelectedApi(value === null ? "" : value);
  };

  const filteredData = data[selectedApp]?.api
    ? Object.entries(data[selectedApp].api).reduce((acc, [api, srvs]) => {
      const apiLowerCase = api.toLowerCase();
      const filteredSrvs = Object.entries(srvs).filter(([srv, params]) => {
        const srvLowerCase = srv.toLowerCase();
        const searchTextLowerCase = searchText.toLowerCase();
        if (
          (apiLowerCase.includes(searchTextLowerCase) ||
            srvLowerCase.includes(searchTextLowerCase)) &&
          (selectedApi === "" || api === selectedApi)
        ) {
          return true;
        }

        const paramsArray = Object.entries(params).map(([param, details]) => {
          const paramLowerCase = param.toLowerCase();
          const detailsLowerCase = details.typ.toLowerCase();
          return `${detailsLowerCase} ${paramLowerCase}`;
        });

        return (
          paramsArray.some((param) => param.includes(searchTextLowerCase)) &&
          (selectedApi === "" || api === selectedApi)
        );
      });

      if (filteredSrvs.length > 0) {
        acc[api] = { api, srvs: Object.fromEntries(filteredSrvs) };
      }

      return acc;
    }, {})
    : {};

  const filteredApiOptions = Object.keys(data[selectedApp]?.api || {}).filter(
    (api) => api.toLowerCase().includes(filteredApi.toLowerCase())
  );

  useEffect(() => {
    const gett = async () => {
      try {
        const response = await axios.get(
          `${param.urlService}${Api}?do=gets&app=${selectedApp}`
        );
        if (!response.data.err) {
          setApi(response.data.jData);
        } else {
          alert(response.data.err);
        }
      } catch (error) {
        alert(error.message);
      }
    };

    if (selectedApp) {
      gett();
    }
  }, [selectedApp]);

  const DeleteApi = async (id) => {
    const data = { id };
    try {
      const response = await axios.post(
        `${param.urlService}${Api}?do=del`,
        data
      );
      console.log("Delete API Response:", response.data);
      if (!response.data.err) {
        setApi((prevApi) => {
          const updatedApi = { ...prevApi };
          delete updatedApi[id];
          return updatedApi;
        });
      } else {
        alert(response.data.err);
      }
    } catch (error) {
      console.error("Error Deleting API:", error);
      alert(`Erreur lors de la suppression de l'API. Veuillez réessayer.`);
    }
  };

  const handleDeleteApi = async (apiName) => {
    if (api && apiName) {
      const foundApi = Object.values(api).find((api) => api.name === apiName);

      if (foundApi) {
        const apiId = foundApi.id;
        const alert = window.confirm("Voulez-vous vraiment supprimer cet API?");
        if (alert) {
          try {
            await DeleteApi(apiId);
            await GetData();
          } catch (error) {
            console.error("Error deleting API:", error);
          }
        }
      }
    }
  };

  const getServices = async (id) => {
    try {
      const response = await axios.get(
        `${param.urlService}${srv}?do=gets&api=${id}`
      );
      if (!response.data.err) {
        setServices(response.data.jData);
      } else {
        alert(response.data.err);
      }
    } catch (message) {
      alert(message);
    }
  };

  const deleteService = async (serviceId) => {
    const data = { id: serviceId };
    try {
      const response = await axios.post(
        `${param.urlService}_srv.php?do=del`,
        data
      );
      console.log("Delete Service Response:", response.data);
    } catch (error) {
      console.error("Error Deleting Service:", error);
      alert(`Erreur lors de la suppression du service. Veuillez réessayer.`);
    }
  };

  const handleDeleteService = async (apiName, serviceName) => {
    if (api && apiName) {
      let foundApi = Object.values(api).find((api) => api.name === apiName);
      if (foundApi) {
        const apiId = foundApi.id;
        await getServices(apiId);
        let foundService = services.find((service) => service.name === serviceName);
        if (foundService) {
          const serviceId = foundService.id;
          const alert = window.confirm("Voulez-vous vraiment supprimer ce Service?");
          if (alert) {
            try {
              await deleteService(serviceId);
              setServices((prevServices) =>
                prevServices.filter((service) => service.id !== serviceId)
              );
              await GetData();
            } catch (error) {
              console.error("Error deleting service:", error);
            }
          }
        }
      }
    }
  };

  return (
    <>
      <div style={{ margin: "80px 10px 0px 0px" }}>
        <div className="header">
          <div className="filter">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiMagnify} size={1} />
                  </InputAdornment>
                ),
              }}
              placeholder="Recherche ..."
              onChange={handleInputChange}
              type="text"
              value={searchText}
            />

            <Autocomplete
              options={filteredApiOptions}
              onChange={handleApiFilterChange}
              renderInput={(apis) => (
                <TextField {...apis} label="Sélectionner une API" />
              )}
              style={{ width: "200px" }}
            />
            <AddAllDialog
              GetData={GetData}
              updateData={updateData}
              apps={apps}
              selectedApp={selectedApp}
            />
          </div>
        </div>
        <div style={{ margin: "10px ", overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 230px)",
              overflowX: "hidden",
            }}
          >
            <Table className="tab-bordure">
              <TableHead>
                <TableRow
                  className="head-color"
                  style={{ position: "sticky", top: 0, zIndex: 1000 }}
                >
                  <TableCell className="head-color">API</TableCell>
                  <TableCell className="head-color">Service</TableCell>
                  <TableCell className="head-color">Inputs</TableCell>
                  <TableCell className="head-color">Outputs</TableCell>
                  <TableCell className="head-color"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(filteredData).map(([api, srvs]) =>
                  api.length > 0
                    ? Object.entries(srvs.srvs).map(
                      ([srv, params], indexsrvs) => (
                        <TableRow key={`${api}-${srv}`} className="tableRow">
                          {indexsrvs === 0 && (
                            <TableCell
                              className="api-cell"
                              rowSpan={Object.keys(srvs.srvs).length}
                            >
                              <Tooltip title="Supprimer Api" arrow>
                                <IconButton
                                  onClick={() => handleDeleteApi(api)}
                                >
                                  <Icon
                                    className="deletebutton"
                                    style={{
                                      color: "rgb(212, 34, 34) ",
                                      width: "2.5px",
                                    }}
                                    path={mdiDeleteOutline}
                                    size={1}
                                  />
                                </IconButton>
                              </Tooltip>
                              {api}
                            </TableCell>
                          )}
                          <TableCell className="api-cell">{srv}</TableCell>
                          <TableCell className="api-cell">
                            {Object.entries(params)
                              .filter(
                                ([param, details]) =>
                                  details.direction === "1"
                              )
                              .map(([param, details]) => (
                                <React.Fragment
                                  key={`${param} ${details.typ}`}
                                >
                                  <span className="detailTyp">
                                    {details.typ}{" "}
                                  </span>
                                  <span>{param}</span>
                                  <span>
                                    {details.must ? "*" : null}
                                  </span>{" "}
                                  {","}
                                </React.Fragment>
                              ))}
                          </TableCell>
                          <TableCell className="api-cell">
                            {Object.entries(params)
                              .filter(
                                ([param, details]) =>
                                  details.direction === "2"
                              )
                              .map(([param, details]) => (
                                <React.Fragment
                                  key={`${param} ${details.typ}`}
                                >
                                  <span className="detailTyp">
                                    {details.typ}{" "}
                                  </span>
                                  <span>{param}</span>
                                  <span>{details.must ? "*" : null}</span>
                                </React.Fragment>
                              ))}
                          </TableCell>
                          <TableCell style={{ width: "50px" }}>
                            <Tooltip title="Supprimer Service" arrow>
                              <IconButton
                                onClick={() => handleDeleteService(api, srv)}
                              >
                                <Icon
                                  style={{
                                    color: "rgb(212, 34, 34) ",
                                    alignItems: "flex-end",
                                  }}
                                  path={mdiDeleteOutline}
                                  size={1}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    )
                    : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* <ChaimaFooter /> */}
      </div>
    </>
  );
}
