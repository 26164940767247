import React, { Fragment, useState } from "react";
import { mdiDelete, mdiDeleteSweep } from '@mdi/js';
import Icon from "@mdi/react";
import { CircularProgress, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import axios from "axios";
import { getHeader } from "../Gfunc";
import param from '../param.json';
import EditedParam from "./FellaParam";
import EditedText from "./FellaEditableText";

export default function BasicTable({ selectedData, selectedApp, fetchData, loading }) {
    // console.log("Props dans EditedParam :", { fetchData, selectedApp });

    const apiEndpoint = `${param.urlService}_api.php`;
    const serviceEndpoint = `${param.urlService}_srv.php`;
    const [editingApi, setEditingApi] = useState({ id: null, newName: '' });
    const [editingService, setEditingService] = useState({ id: null, newName: '' });
    const [apis, setApis] = useState([]);




    const deleteApi = async (apiId) => {
        const alert = window.confirm("Voulez-vous vraiment supprimer cet API?");
        if (alert)
            try {
                await axios.post(`${apiEndpoint}?do=del`, { id: apiId }, { headers: getHeader() });
                fetchData(selectedApp);
            } catch (error) {
                alert(`Error deleting API: ${error.message}`);
            }
    };

    const deleteService = async (serviceId) => {
        const alert = window.confirm("Voulez-vous vraiment supprimer ce service?");
        if (alert)
            try {
                await axios.post(`${serviceEndpoint}?do=del`, { id: serviceId }, { headers: getHeader() });
                fetchData(selectedApp);
            } catch (error) {
                alert(`Error deleting service: ${error.message}`);
            }
    };
    const handleEditApiName = (apiId, currentName) => {
        setEditingApi({ id: apiId, newName: currentName });
    };

    const handleSaveApiName = async (apiId, newApiName) => {
        if (newApiName.trim() === '' || apis.some((api) => api.name === newApiName && api.id !== apiId)) {
            alert('Veuillez choisir un nom unique et non vide.');
            return;
        }

        try {
            await axios.post(`${apiEndpoint}?do=update`, { id: apiId, name: newApiName }, { headers: getHeader() });
            fetchData(selectedApp);
            setEditingApi({ id: null, newName: '' });
        } catch (error) {
            alert(`Error updating API name: ${error.message}`);
        }
    };
    const handleEditServiceName = (serviceId, currentName) => {
        setEditingService({ id: serviceId, newName: currentName });
    };

    const handleSaveServiceName = async (serviceId, newServiceName) => {
        try {
            // Effectuer la requête pour mettre à jour le nom du service
            await axios.post(`${serviceEndpoint}?do=update`, { id: serviceId, name: newServiceName }, { headers: getHeader() });
            fetchData(selectedApp);

            // Mettre à jour localement les données après la modification réussie
            const updatedApis = apis.map(api => {
                const updatedServices = { ...api.services };
                if (updatedServices[serviceId]) {
                    updatedServices[serviceId].serviceName = newServiceName;
                }
                return { ...api, services: updatedServices };
            });
            setApis(updatedApis);

            // Réinitialiser l'état de l'édition
            setEditingService({ id: null, newName: '' });
        } catch (error) {
            alert(`Erreur lors de la mise à jour du nom du service : ${error.message}`);
        }
    };

    return (
        <div>
            {loading && (
                <div id='boxCircularProgress'>
                    <CircularProgress color="primary" variant="indeterminate" sx={{ justifyContent: 'flex-center' }} />
                </div>
            )}

            <div className="table-container">
                <TableContainer component={Paper} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 230px)', overflowX: 'hidden' }}>
                    <Table aria-label="simple table" className="table" stickyHeader>
                        <TableHead>
                            <TableRow className="table-header">
                                <Fragment>
                                    <TableCell className="thead1 smaller-cell tab" style={{ backgroundColor: '#1B7EBE' }}>API</TableCell>
                                    <TableCell className="thead2 smaller-cell tab" style={{ backgroundColor: '#1B7EBE' }}>SERVICE</TableCell>
                                    <TableCell className="thead3 tab input-cell1" style={{ backgroundColor: '#1B7EBE' }}>INPUT</TableCell>
                                    <TableCell className="thead4 tab output-cell1" style={{ backgroundColor: '#1B7EBE' }}>OUTPUT</TableCell>
                                    <TableCell className="thead5 smaller-cell1" style={{ backgroundColor: '#1B7EBE' }}></TableCell>
                                </Fragment>
                            </TableRow>
                        </TableHead>

                        {!loading && (
                            <TableBody>
                                {selectedData?.apis &&
                                    Object.entries(selectedData.apis).map(([apiId, apiData]) => (
                                        <Fragment key={apiId}>
                                            {apiData && Object.entries(apiData.services).map(([serviceId, serviceData], serviceIndex) => (
                                                <TableRow key={serviceId} className="table-row">
                                                    {serviceIndex === 0 && (
                                                        <TableCell className="table-cell smaller-cell api" rowSpan={Object.keys(apiData.services).length}>
                                                            {editingApi.id === apiId ? (
                                                                <EditedText
                                                                    att='name'
                                                                    api={"_api.php"}
                                                                    service={"upd"}
                                                                    aux={`&app=${selectedApp}`}
                                                                    onSave={(newName) => handleSaveApiName(apiId, newName)}
                                                                    setEditApiId={editingApi.id}
                                                                    defaultValue={apiData.apiName}
                                                                    id={apiId}
                                                                />
                                                            ) : (
                                                                <div onClick={() => handleEditApiName(apiId, apiData.apiName)}>
                                                                    <Tooltip title='Supprimer API' arrow>
                                                                        <IconButton onClick={() => deleteApi(apiId)}>
                                                                            <Icon className="supbutton" path={mdiDeleteSweep} size={1} style={{ color: '#BF2B38' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {apiData.apiName}
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    <TableCell className="smaller-cell service-cell">
                                                        {editingService.id === serviceId ? (
                                                            <EditedText
                                                                att='name'
                                                                api={"_srv.php"}
                                                                service={"upd"}
                                                                aux={`&api=${apiId}`}
                                                                onSave={(newName) => handleSaveServiceName(serviceId, newName)}
                                                                setEditServiceId={editingService.id}
                                                                defaultValue={serviceData.serviceName}
                                                                id={serviceId}
                                                            />
                                                        ) : (
                                                            <div onClick={() => handleEditServiceName(serviceId, serviceData.serviceName)}>
                                                                {serviceData.serviceName}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="input-cell">
                                                        <Tooltip title={serviceData.noteIn}>
                                                            {serviceData.params
                                                                .filter(param => param.direction === "1")
                                                                .map((param, index) => (
                                                                    <span key={param.paramId} className="param-info">
                                                                        <span className="param-type">{param.typ}</span>
                                                                        <span className="param-name">{param.paramName || '--'}</span>
                                                                        <span className="param-must">{param.must === "1" ? '*' : ''}</span>
                                                                        {index < serviceData.params.filter(param => param.direction === "1").length - 1 && <span style={{ paddingRight: "7px" }}>,</span>}
                                                                    </span>
                                                                ))}
                                                        </Tooltip>
                                                    </TableCell>

                                                    <Tooltip title={serviceData.noteOut}>
                                                        <TableCell className="output-cell">
                                                            {serviceData.params
                                                                .filter(param => param.direction === "2")
                                                                .map((param, index) => (
                                                                    <span key={param.paramId} className="param-info">
                                                                        <span className="param-type">{param.typ}</span>
                                                                        <span className="param-name">{param.paramName || '--'}</span>
                                                                        <span className="param-must">{param.must === "1" ? '*' : ''}</span>
                                                                        {index < serviceData.params.filter(param => param.direction === "2").length - 1 && <span style={{ paddingRight: "7px" }}>, </span>}
                                                                    </span>
                                                                ))}
                                                        </TableCell>
                                                    </Tooltip>


                                                    <TableCell className="smaller-cell1 ">
                                                        <div className="twoIcons">
                                                            <EditedParam serviceData={serviceData} fetchData={fetchData} selectedApp={selectedApp} />
                                                            <Tooltip title='Supprimer Service' arrow>
                                                                <IconButton onClick={() => deleteService(serviceId)}>
                                                                    <Icon path={mdiDelete} size={1} style={{ color: '#BF2B38' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </Fragment>
                                    ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
