import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { getHeader } from '../Gfunc';
import EditedText from './FellaEditableText';
import '../style/Fellaglobal.css';
import param from '../param.json'
export default function EditedTab(props) {
  const [apps, setApps] = useState([]);
  const [editAppId, setEditAppId] = useState(null);
  const api = '_app.php';


  const getApps = async () => {
    await axios.get(`${param.urlService}${api}?do=gets`, { headers: getHeader() })
      .then((response) => {
        setApps(response.data.jData);
      })
      .catch((message) => {
        alert(message);
      });
  };




  const delApp = async (appId) => {
    var data = { id: appId };
    try {
      await axios.post(`${param.urlService}${api}?do=del`, data, {
        headers: getHeader(),
      });
      setApps((prevApps) => prevApps.filter((app) => app.id !== appId));
    } catch (err) {
      console.log(err);
    }
  };


  const handleDeleteAPP = async (appId) => {
    if (appId) {
      const alert = window.confirm("Voulez-vous vraiment supprimer cette application?");
      if (alert) {
        await delApp(appId);
        props.handleDeleteApp(appId);
      }
    }
  };



  const handleSaveappName = (appId, newAppName) => {
    console.log("appId:", appId);
    console.log("newAppName:", newAppName);

    if (newAppName.trim() === '' || apps.some((app) => app.name === newAppName && app.id !== appId)) {
      alert('Veuillez choisir un nom unique et non vide.');
      return;
    }
    const { updateAppName } = props;
    updateAppName(appId, newAppName);
    setEditAppId(null);
    handlesetnameapp(appId, newAppName);
  };


  const handleEditAppName = (appId) => {
    setEditAppId(appId);
  };

  useEffect(() => {
    getApps();
  }, []);


  const handlesetnameapp = (id, newAppName) => {
    const updatedApps = apps.map((app) => app.id === id ? { ...app, name: newAppName } : app);
    setApps(updatedApps)
  };


  return (
    <table style={{ marginTop: "50px" }}>
      <tbody className='grid-container'>
        {apps.map((app) => (
          <tr key={app.id} className='grid-item' >
            <td style={{ paddingLeft: '11px' }} >

              {editAppId === app.id ? (
                <EditedText app={app} api={"_app.php"}
                  service={"upd"} onSave={handleSaveappName} setEditAppId={setEditAppId} id={app.id} defaultValue={app.name}
                />
              ) : (
                <div onClick={() => handleEditAppName(app.id)} >
                  {app.name}
                </div>
              )}
            </td>
            <td className='smaller-cell ' >
              {editAppId !== app.id && (
                <Tooltip title='Supprimer'>
                  <IconButton onClick={() => handleDeleteAPP(app.id)}>
                    <Icon path={mdiDelete} size={1} style={{ color: '#BF2B38' }} />
                  </IconButton>
                </Tooltip>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

