import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {  IconButton,  TextField } from '@mui/material';
import { mdiClose, mdiPlusThick} from '@mdi/js';
import Icon from '@mdi/react';
import Tooltip from "@mui/material/Tooltip";
function PaperComponent(props) {

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AddAppDialog({handleAddApp}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [newAppName, setNewAppName] = React.useState("");

  
  const handleSubmit = (event) => {
    event.preventDefault(); 
    handleAddApp(newAppName);
    handleClose();
  };
 

  const handleClickOpen = () => {
    setOpen(true);
    setNewAppName("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment> <div >
     <Tooltip title="Ajouter une application" arrow>
  <Button 
    onClick={handleClickOpen}
    startIcon={<Icon path={mdiPlusThick} title="User Profile" size={"18px"} />}
    style={{ height: "40px", color: "grey" }}
 ></Button>
</Tooltip></div>

      <Dialog

        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={fullScreen}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Ajouter le nom de l'application
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
        <Icon path={mdiClose} size={1} />
        </IconButton>
        </DialogTitle>
            <DialogContent>
            <form id="addApp"  style={{width:"500px",marginTop:"20px"}} onSubmit={handleSubmit}>
                <TextField 
                    label ="Nom de l'application"
                    variant="outlined"
                    fullWidth
                    onChange={(e)=>{setNewAppName(e.target.value)}}
                    required
                />
              
            </form>
        </DialogContent>
        <DialogActions>
            <Button form="addApp" type='submit' >Ajouter</Button>
            
          <Button onClick={handleClose}>Annuler</Button> 
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}