// import React, { useState, useEffect } from 'react';
// import ClickableAway from './FellaClickableAway';
// import axios from 'axios'; import { getHeader } from '../Gfunc';
// import { TextField } from '@mui/material';
// import param from '../param.json'

// const EditedText = ({ api, service, aux, id, defaultValue, onSave }) => {
//   const [value, setValue] = useState(defaultValue);


//   const handleSaveName = () => {
//     onSave(id, value);
//     updApp();
//   };

//   const updApp = async () => {
//     const data = { att: 'name', id: id, val: value };
//     try {
//       const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
//       console.log(response.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSaveName();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyPress);
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <ClickableAway onClickAway={handleSaveName}>
//       <div>
//         <TextField
//           className='Edit-Name'
//           type='text'
//           autoFocus
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
//           onKeyDown={handleKeyPress}
//         />
//       </div>
//     </ClickableAway>
//   );
// };
// export default EditedText;

import React, { useState, useEffect } from 'react';
import ClickableAway from './FellaClickableAway';
import axios from 'axios'; import { getHeader } from '../Gfunc';
import { TextField } from '@mui/material';
import param from '../param.json'
const EditedText = ({ api, service, aux, id, defaultValue, onSave, att }) => {
  const [value, setValue] = useState(defaultValue);




  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveName();
    }
  };

  const handleSaveName = () => {
    onSave(id, value);
    updApp();
  };


  const updApp = async () => {
    let val;
    if (typeof value === 'object' && value !== null && 'id' in value) {
      val = value.id; // Si value est un objet et possède une propriété "id", utilisez cette valeur
    } else {
      val = value; // Sinon, utilisez simplement la valeur de "value"
    }

    const data = { att: att, id: id, val: val };
    try {
      const response = await axios.post(`${param.urlService}${api}?do=${service}` + (aux !== undefined ? `${aux}` : ""), data, { headers: getHeader() });
      console.log(response.data);
      // Appeler onSave avec la nouvelle valeur
      onSave(value);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <ClickableAway onClickAway={handleSaveName}>
      <div>
        <TextField
          className='Edit-Name'
          type='text'
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
    </ClickableAway>
  );
};
export default EditedText;




